import React, { useState } from 'react'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import $ from "jquery";

const AddEmployeePopup = (props) => {

    const [getName, setName] = useState('');
    const [getRole, setRole] = useState('');
    const [getEmail, setEmail] = useState('');
    const [getPassword, setPassword] = useState('');
    const [getPhoto, setPhoto] = useState('');

    const baseURL = process.env.REACT_APP_BACKEND_URL +"employee";





    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = event => {
        event.preventDefault();
        const Employee = {
            name: getName,
            Role: getRole,
            email: getEmail,
            password: getPassword,
            Photo: setPhoto
        }

        axios.post(baseURL, Employee)
            .then(res => {
                props.onInsert();
                setName('');
                setRole('');
                setEmail('');
                setPhoto('');
            })

        handleClose();
    }
    const setProfileImage = (event) => {
        const file = event.target.files[0];
        console.log(file);
        // const reader = new FileReader();
        // reader.onload = function () {
        //     // setPhoto(reader.result);
        //     setPhoto(test);
        // }
        // reader.readAsDataURL(file);
    }


    return (
        <div>

            <button type="button" className="btn btn-primary" onClick={handleShow}>
                Add New User
            </button>

            <Modal show={show} onHide={handleClose}>

                <form onSubmit={handleSubmit}>

                    <Modal.Header closeButton>
                        <Modal.Title>New Employee</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="modal-body">
                            <div className="mb-3 row">
                                <label htmlFor="txtName" className="col-sm-2 col-form-label">Name</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="txtName" required
                                        onChange={event => setName(event.target.value)} value={getName} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="txtEmail" className="col-sm-2 col-form-label">Email</label>
                                <div className="col-sm-10">
                                    <input type="email" autoComplete='off' className="form-control" id="txtEmail" required
                                        onChange={event => setEmail(event.target.value)} value={getEmail} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="txtEmail" className="col-sm-2 col-form-label">Password</label>
                                <div className="col-sm-10">
                                    <input type="text" autoComplete='off' className="form-control" id="txtPassword" required
                                        onChange={event => setPassword(event.target.value)} value={getPassword} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="txtAge" className="col-sm-2 col-form-label">Role</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="txtAge" required
                                        onChange={event => setRole(event.target.value)} value={getRole} />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label htmlFor="formFile" className="col-sm-2 col-form-label">P. Image</label>
                                <div className="col-sm-10">
                                    <input className="form-control" type="file" id="formFile"
                                        onChange={event => { setProfileImage(event) }} />
                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary">Save changes</button>
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                    </Modal.Footer>

                </form>

            </Modal>

        </div>
    )
}

export default AddEmployeePopup
