import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { GetToken } from '../utils/GetToken'
import { Link } from 'react-router-dom'
import { Toast, ToastContainer } from 'react-bootstrap'

export const Followers = () => {
  const baseURL = process.env.REACT_APP_BACKEND_URL
  const [getListOfFollowers, setListOfFollowers] = useState()

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  const ToastMessage = ({ show, onClose, message }) => {
    return (
      <Toast show={show} onClose={onClose}>
        <Toast.Header>
          <strong className='me-auto'>{message.cat}</strong>
        </Toast.Header>
        <Toast.Body>{message.content}</Toast.Body>
      </Toast>
    )
  }

  const handleShowToast = message => {
    setToastMessage(message)
    setShowToast(true)

    // Hide the toast after a certain duration
    setTimeout(() => {
      setShowToast(false)
    }, 5000) // 3 seconds
  }

  const getFollowers = async userId => {
    GetToken()
    await axios
      .get(baseURL + 'Employee/Followers/')
      .then(response => {
        setListOfFollowers(response.data)
      })
      .catch(error => {
        console.log(error.response.data.message)
      })
  }

  const userFollow = async (id, quote) => {
    let apiData = {
      FollowedTo: id
    }
    GetToken()
    await axios.post(baseURL + 'Employee/Follow', apiData).then(response => {
      // props.getData();
      getFollowers()
    })

    let message = { cat: 'Follow Status', content: quote }
    handleShowToast(message)
  }

  useEffect(() => {
    getFollowers()
  }, [])

  return (
    <div className='container-fluid'>
      <div className='row my-3 gx-3'>
        <ToastContainer
          style={{ top: '20px', right: '20px', position: 'fixed' }}
          className='p-3'
        >
          <ToastMessage
            show={showToast}
            onClose={() => setShowToast(false)}
            message={toastMessage}
          />
        </ToastContainer>

        {getListOfFollowers?.map((item, index) => {
          return (
            <div className='col-6 col-lg-2' key={index}>
              <div className='card'>
                <div className='card-body text-center'>
                  <div className='square-container'>
                    <img
                      src={baseURL + 'uploads/' + item.resultUser[0]?.photo}
                      className='square-image'
                    />
                  </div>
                  <h6 className='my-3'>{item.resultUser[0]?.name}</h6>
                  <p className='text-muted mb-1'>Quotes : {item.totalQuotes}</p>
                  <p className='text-muted mb-1'>
                    Follower : {item.totalFollowers}
                  </p>
                  <p className='text-muted mb-4'>
                    Following : {item.totalFollowing}
                  </p>
                  <div className='d-flex justify-content-center mb-2'>
                    <button
                      type='button'
                      className='btn btn-primary btn-sm me-2'
                      onClick={() => userFollow(item.FollowedBy, (item.isFollow == 0 ? 'Follow' : 'Unfollow') + " - " + item.resultUser[0]?.name)}
                    >
                      {item.isFollow == 0 ? 'Follow' : 'Unfollow'}
                    </button>
                    <Link
                      to={'/user/' + item.FollowedBy}
                      type='button'
                      className='btn btn-primary btn-sm '
                    >
                      Quotes
                    </Link>
                    {/* <button type="button" className="btn btn-outline-primary ms-1">Message</button> */}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        {/* {getListOfFollowers.map((item, index) => {
          return (
            <div>test</div>
          )})}; */}
      </div>
    </div>
  )
}
