// import axios from 'axios';
import { faGrinHearts, faSignOutAlt, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, NavDropdown } from "react-bootstrap";
import axios from "axios";
const Header = () => {
  const baseURL = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();

  const [showDropdown, setShowDropdown] = useState(false);
  const [getCategoryList, setCategoryList] = useState([]);
  const [getAuthorList, setAuthorList] = useState([]);

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const userTokenData = JSON.parse(
    Cookies.get("user") ? Cookies.get("user") : "{}"
  );
  const token = Cookies.get("user") ? userTokenData.jwt : "";
  const name = Cookies.get("user") ? userTokenData.name : "";
  const role = Cookies.get("user") ? userTokenData.role : "";
  const image = Cookies.get("user") ? userTokenData.image : "";

  const eventLogout = (e) => {
    e.preventDefault();
    Cookies.remove("jwt");
    Cookies.remove("name");
    Cookies.remove("role");
    Cookies.remove("user");
    window.location.hash = "login";
  };

  useEffect(() => {
    categoryList();
    authorList();
  }, []);
  const categoryList = async () => {
    await axios.get(baseURL + "category").then((res) => {
      setCategoryList(res.data);
    });
  };

  const authorList = async () => {
    await axios.get(baseURL + "author").then((res) => {
      setAuthorList(res.data);
    });
  };


  // Close the mobile menu when a link is clicked
  const closeMobileMenu = () => {
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.querySelector('.navbar-collapse');

    if (navbarToggler && navbarCollapse.classList.contains('show')) {
      navbarToggler.click();
    }
  };

  useEffect(() => {
    closeMobileMenu(); // Close the menu when the page loads or when the route changes
  }, [location.pathname]);

  return (
    <header>
      <div className="sticky-top">
        <nav className="navbar navbar-expand-lg bg-light">
          <div className="container-fluid">
            <Link className="nav-link" to="/"> <img src="./quote-logo-small.png" style={{ height: '25px' }} /> Quotes </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse navbar-nav-scroll"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto mb-2 mb-lg-0">

                {token ? (
                  <li className="nav-item d-block d-lg-none mt-2">
                    <div className="text-center" >

                      <div
                        style={{
                          position: 'relative',
                          overflow: 'hidden',
                          height: '150px',
                          display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}
                      >
                        <img src={baseURL + "uploads/" + image} className="img-fluid rounded-circle shadow-4" style={{ height: '100px', width: '100px', marginRight: '5px', border: '2px solid #FFFFFF', position: 'relative', zIndex: '2' }} />
                        <div
                          style={{
                            content: '""',
                            backgroundImage: 'url(' + baseURL + "uploads/" + image + ')',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            filter: 'blur(5px)', // Apply blur effect to the background image
                            zIndex: 1, // Place it behind the foreground image
                          }}
                        />
                      </div>
                      <h3 className="mt-3">{name}</h3>
                      <div className="hamburger-profile mt-3">
                        <Link to={"/profile"} >
                          <FontAwesomeIcon
                            icon={faUserEdit}
                            className="text-primary circular-icon bg-primary text-white"
                          />
                        </Link>
                        <FontAwesomeIcon
                          onClick={eventLogout}
                          icon={faSignOutAlt}
                          className="text-primary circular-icon  bg-primary text-white"
                        />
                      </div>
                    </div>
                    {/* <hr className="my-1" /> */}
                  </li>
                ) : ''}
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/"> Home </Link>
                </li>
                <li className="nav-item dropdown megamenu">
                  <span id="dropdownMenuCategory" data-bs-toggle="dropdown"
                    className="nav-link dropdown-toggle" >Category</span>
                  <div aria-labelledby="dropdownMenuCategory" className="dropdown-menu border-0 p-0 m-0">
                    <div className="">
                      <div className="row bg-white m-0">
                        {getCategoryList.map((item, index) => (

                          item.totalQuotesByCategory > 0 ?
                            <div key={index} className="col-6 col-md-3" >
                              <Link className="nav-link" to={"/category/" + item._id} >  {item.name}  ({item.totalQuotesByCategory})</Link>
                            </div>
                            : ''
                        ))}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown megamenu">
                  <span id="dropdownMenuAuthor" data-bs-toggle="dropdown"
                    className="nav-link dropdown-toggle" >Author</span>
                  <div aria-labelledby="dropdownMenuAuthor" className="dropdown-menu border-0 p-0 m-0">

                    <div className="">
                      <div className="row bg-white m-0">
                        {getAuthorList.map((item, index) => (
                          item.totalQuotesByAuthor > 0 ?
                          <div key={index} className="col-6 col-md-3" >
                            <Link className="nav-link p-1" to={"/author/" + item._id} >  {item.name} ({item.totalQuotesByAuthor}) </Link>
                          </div>
                          : ''
                        ))}
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li className="nav-item">
                  <Link aria-current="page" to="/wallpaper" className={(location.pathname === "/wallpaper" ? "active" : "") + " nav-link"} > wallpaper </Link>
                </li> */}
                <li className="nav-item dropdown megamenu">
                  <Link to="/wallpaper" id="dropdownMenuWallpaper" data-bs-toggle="dropdown"
                    className="nav-link dropdown-toggle" >✨ Free Wallpaper </Link>
                  <div aria-labelledby="dropdownMenuWallpaper" className="dropdown-menu border-0 p-0 m-0">
                    <div className="row bg-white m-0">
                      <div className="col-6">

                        <div className="row pt-2">
                          <div className="col-6">Category</div>
                          <div className="col-6 text-end h6"><Link to="/wallpaper">View all</Link></div>
                          {getCategoryList.map((item, index) => (
                            <div key={index} className="col-12 col-md-3" >
                              <Link className="nav-link px-0 py-1" to={"/wallpaper/category/" + item.name} >  {item.name}</Link>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="col-6 vertical-divider">
                        <div className="row pt-2">

                          <div className="col-6 ">Author</div>
                          <div className="col-6 text-end h6"><Link to="/wallpaper">View all</Link></div>
                          {getAuthorList.map((item, index) => (
                            <div key={index} className="col-12 col-md-3" >
                              <Link className="nav-link px-0 py-1" to={"/wallpaper/author/" + item.name} >  {item.name} </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>


                  </div>
                </li>
                {role ? (
                  <>
                    <li className="nav-item d-block d-md-none">
                      <Link aria-current="page" to="/" className={(location.pathname === "/my-quotes" ? "active" : "") + " nav-link"} > My Quote </Link>
                    </li>
                    <li className="nav-item d-block d-md-none">
                      <Link aria-current="page" to="/favorite" className={(location.pathname === "/favorite" ? "active" : "") + " nav-link"} > My Favorite </Link>
                    </li>
                    <li className="nav-item d-block d-md-none">
                      <Link aria-current="page" to="/followers" className={(location.pathname === "/followers" ? "active" : "") + " nav-link"} > My Followers </Link>
                    </li>
                    <li className="nav-item d-block d-md-none">
                      <Link aria-current="page" to="/following" className={(location.pathname === "/following" ? "active" : "") + " nav-link"} > My Following </Link>
                    </li>
                    <li className="nav-item d-block d-md-none">
                      <Link aria-current="page" to="/inbox" className={(location.pathname === "/inbox" ? "active" : "") + " nav-link"} > Inbox </Link>
                    </li>
                  </>
                ) : (
                  ""
                )}

                {/* <li className="nav-item">
                  <Link aria-current="page" to="/help" className={(location.pathname === "/help" ? "active" : "") + " nav-link"} > Help </Link>
                </li> */}
              </ul>
              <div >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {token ? (<li className="nav-item">
                    <div className="navbar navbar-expand-lg navbar-light bg-light">
                      <div
                        className={`collapse navbar-collapse ${showDropdown ? "show" : ""
                          }`}
                      >
                        <ul className="navbar-nav ml-auto">
                          <li className="nav-item dropdown ">
                            <Dropdown
                              show={showDropdown}
                              onToggle={handleDropdownToggle}
                            >
                              {image ?
                                <img src={baseURL + "uploads/" + image}
                                  className="img-fluid rounded-circle shadow-4 me-1"
                                  alt={name}
                                  style={{ height: '40px', width: '40px' }} />

                                : ''}
                              <Dropdown.Toggle
                                id="profile-dropdown"
                              >

                                {name}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="p-0" style={{ minWidth: '100%' }}>


                                <Dropdown.Item href="#/profile" className="px-2">
                                  My Profile
                                </Dropdown.Item>
                                <Dropdown.Divider className="my-0" />
                                <Dropdown.Item href="#/favorite" className="px-2">
                                  My Favorite Quotes
                                </Dropdown.Item>
                                <Dropdown.Item href="#/my-quotes" className="px-2">
                                  Quote By Me
                                </Dropdown.Item>
                                <Dropdown.Item href="#/wallpaper/user/0" className="px-2">
                                  Wallpaper Created By me
                                </Dropdown.Item>
                                <Dropdown.Item href="#/followers" className="px-2">
                                  My Followers
                                </Dropdown.Item>
                                <Dropdown.Item href="#/following" className="px-2">
                                  I am Following
                                </Dropdown.Item>
                                <Dropdown.Divider className="my-0" />
                                <Dropdown.Item onClick={eventLogout} className="px-2">
                                  Logout
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  ) : (
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        aria-current="page"
                        to="/login"
                      >
                        Login
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {role && role === "Admin" ? (
        <div className="container-fluid mt-3">
          <ul className="list-group list-group-horizontal position-relative overflow-auto">
            {/* <li className="list-group-item">Admin :</li> */}
            <li className="list-group-item">
              <Link className="nav-link" aria-current="page" to="/user">
                User
              </Link>
            </li>
            <li className="list-group-item">
              {" "}
              <Link className="nav-link" aria-current="page" to="/quotes">
                Quotes
              </Link>
            </li>
            <li className="list-group-item">
              {" "}
              <Link
                className="nav-link"
                aria-current="page"
                to="/admin/category"
              >
                Category
              </Link>
            </li>
            <li className="list-group-item">
              {" "}
              <Link className="nav-link" aria-current="page" to="/admin/author">
                Author
              </Link>
            </li>
            <li className="list-group-item">
              {" "}
              <Link
                className="nav-link"
                aria-current="page"
                to="/admin/feedback-list"
              >
                Feedbacks
              </Link>
            </li>
            <li className="list-group-item">Other</li>
          </ul>
        </div>
      ) : (
        ""
      )}
    </header>
  );
};

export default Header;
