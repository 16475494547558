import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { EditQuoteWallpaper } from '../Components/EditQuoteWallpaper';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesStacked, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import ReactGA, { pageview } from 'react-ga';
import { GetToken } from '../utils/GetToken';
import Cookies from 'js-cookie';

export const QuoteWallpaper = () => {
    const baseURL = process.env.REACT_APP_BACKEND_URL
    const { categoryName } = useParams();
    const { authorName } = useParams();
    const { user } = useParams();
    const { quoteId } = useParams();
    const [loading, setLoading] = useState(false);
    // const pageRef = useRef(1);
    const [wallpaper, setWallpaper] = useState([]);
    let page = 0;
    let startNextDownload = true;

    const [showPopup, setShowPopup] = useState(false);
    const [popupValue, setPopupValue] = useState("");
    const openPopup = (value) => {
        setPopupValue(value);
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };



    const handleObserver = (entities) => {
        const target = entities[0];
        if (startNextDownload) {
            if (page == 0) {
                page = 1;
                getWallpaper();
            }
            else {
                if (target.isIntersecting) {
                    page++
                    getWallpaper(); // Load more data when the target element is in view
                }
            }
        }
    };

    useEffect(() => {
        page = 0;
        // getWallpaper();
        const observer = new IntersectionObserver(handleObserver, {
            root: null, // Use the viewport as the root
            rootMargin: '10px', // No margin
            threshold: 1.0, // Fully visible
        });

        const target = document.querySelector('.scroll-trigger'); // Element at the bottom of the content
        if (target) {
            observer.observe(target);
        }
        return () => observer.disconnect(); // Cleanup when unmounting
    }, [categoryName, authorName, user, quoteId])

    const getWallpaper = async () => {
        setLoading(true);

        let pageName = '';

        let apiUrl = "";

        if (categoryName !== undefined) {
            apiUrl = baseURL + 'quote/get-wallpaper/category/' + categoryName + '?page=' + page;
            pageName = "Wallpaper - " + categoryName;
        }
        else if (authorName !== undefined) {
            apiUrl = baseURL + 'quote/get-wallpaper/author/' + authorName + '?page=' + page;
            pageName = "Wallpaper - " + authorName;
        }
        else if (user !== undefined) {
            if (Cookies.get('user') != undefined) GetToken()
            apiUrl = baseURL + 'quote/get-wallpaper/user/' + user + '?page=' + page;
            pageName = "Wallpaper - " + user;
        }
        else if (quoteId !== undefined) {
            apiUrl = baseURL + 'quote/get-wallpaper/quoteId/' + quoteId + '?page=' + page;
            pageName = "Wallpaper - By Quote";
        }
        else {
            apiUrl = baseURL + 'quote/get-wallpaper?page=' + page;
            pageName = "Wallpaper";
        }

        ReactGA.pageview(pageName);
        document.title = pageName + ' | ' + process.env.REACT_APP_Name;

        await axios
            .get(apiUrl)
            .then(response => {


                if (page == 1) {
                    setWallpaper(response.data)
                }
                else {
                    setWallpaper((getWallpaper) => [...getWallpaper, ...response.data]);
                }
                if (response.data.length < 5) {
                    startNextDownload = false;
                }
            })
            .catch(error => {
                console.log(error.response.data.message)
            })
        setLoading(false);

        // pageRef.current++;
    }

    const openPopupForImage = (quoteWallpaperData) => {
        openPopup({ ...quoteWallpaperData })


    }


    return (
        <div className='my-3'>
            {showPopup ?
                <EditQuoteWallpaper
                    show={showPopup}
                    onHide={closePopup}
                    value={popupValue}
                />
                : ''}
            <div className='d-flex justify-content-center flex-wrap'>
                {wallpaper.map((item, index) => {
                    return (
                        <div key={index} className="wallpaper-thumbnail" >
                            <Link to={`/wallpaper/category/${item.resultCat[0].name.toLowerCase()}`}
                                className='wallpaper-thumbnail-before cursive-text h5  m-0 rounded-top'>
                                {item.resultCat[0].name}</Link>
                            <img src={baseURL + 'quote-wallpaper/' + item.image}
                                alt={item.image} onClick={() => openPopupForImage(item)}
                                className='w-100'
                            />
                            {/* <Link onClick={() => openPopupForImage(item)}
                                className='wallpaper-thumbnail-center m-0 rounded'>
                                <FontAwesomeIcon
                                    icon={faDownload}
                                    title="Download Wallpaper"
                                />
                            </Link> */}
                            <Link to={`/wallpaper/quoteId/${item.quoteId}`}
                                className='wallpaper-thumbnail-similar m-0 rounded'>
                                <FontAwesomeIcon
                                    icon={faBoxesStacked}
                                    title="Similar Wallpaper"
                                    className=''
                                />
                            </Link>
                            {
                                item.resultAuthor[0].name === "By Self" ?

                                    <Link to={`/wallpaper/user/${item.userId}`}
                                        className='wallpaper-thumbnail-after  cursive-text h5 m-0 rounded-bottom'>
                                        {item.resultUser[0].name}
                                    </Link>

                                    :
                                    <Link to={`/wallpaper/author/${item.resultAuthor[0].name.toLowerCase()}`}
                                        className='wallpaper-thumbnail-after  cursive-text h5 m-0 rounded-bottom'>
                                        {item.resultAuthor[0].name}
                                    </Link>
                            }

                        </div>
                    )
                })
                }
            </div>
            {loading && <div className='loader' title="Wallpaper is Loading"></div>}
            <div className="scroll-trigger"></div>
        </div>
    )
}
