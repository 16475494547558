import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react'

export const GetToken = () => {
    const userTokenData = JSON.parse(Cookies.get('user'));
    const token = userTokenData.jwt;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return (
    <div>GetToken</div>
  )
}
