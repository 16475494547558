import axios from 'axios';
import React, { useEffect, useState } from 'react'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const Author = () => {

    const [getTable, setTable] = useState([]);
    const [getAuthor, setAuthor] = useState([]);
    const [getAuthorDetails, setAuthorDetails] = useState([]);

    // GetToken();
    const baseURL = process.env.REACT_APP_BACKEND_URL + "author";

    const getData = async () => {
        await axios.get(baseURL).then((response) => {
            console.log(response.data);
            setTable(response.data);
            // setUser({
            //   ...userData,
            //   name : response.data[0].name, 
            //   age : response.data[0].age,
            //   email : response.data[0].email,
            // })
        })
    }


    const formatDate = (str) => {
        let date = new Date(str);
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        let hour = ("0" + date.getHours()).slice(-2);
        let min = ("0" + date.getMinutes()).slice(-2);
        return [day, month, date.getFullYear()].join("/") + " " + hour + " : " + min;
    }



    useEffect(() => {
        getData();
    }, []);


    const AddAuthor = () => {
        console.log("Add Author : " + getAuthor);

        const author = {
            name: getAuthor,
        }

        axios.post(baseURL, author)
            .then(res => {
                console.log(res);
                console.log(res.data);
                setAuthor('');
                getData();
            })
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const editAuthor = async (id) => {
        await axios.get(baseURL + "/" + id).then((response) => {
            console.log(response.data);
            setAuthorDetails({
                id: response.data._id,
                name: response.data.name
            });
            // console.log("2", getQuoteList, response.data);
            handleShow();
        })

    }

    const updateChanges = async (data) => {
        console.log(data);

        await axios.put(baseURL + "/" + data.id, data)
            .then(res => {
                getData();
                handleClose();
            })
    }


    const deleteAuthor = async (id) => {
        await axios.delete(baseURL + "/" + id);
        getData();
    }


    return (
        <div>

            <table className="table table-striped" border="1">
                <thead>
                    <tr>
                        <td colSpan={4}>
                            Author : <input type="text"
                                onChange={(e) => setAuthor(e.target.value)} value={getAuthor} />
                            <input type="button" value="Add New Author" className="btn btn-primary"
                                onClick={() => { AddAuthor() }} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Author</th>
                        <th scope="col">Date</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getTable.map((item, index) => {
                            return (
                                <tr key={item._id}>
                                    <th>{index + 1}</th>
                                    <td>{item.name}</td>
                                    <td>{formatDate(new Date(item.createdAt))}</td>
                                    <td>
                                        <button className="btn btn-primary  mx-1" onClick={() => editAuthor(item._id)}>Edit</button>
                                        <button className="btn btn-secondary mx-1" onClick={() => deleteAuthor(item._id)}>Delete</button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>


            <Modal show={show} onHide={handleClose}>

                <Modal.Header closeButton>
                    <Modal.Title>Update Quote</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form >
                        <div className="modal-body">

                            <div className="mb-3 row">
                                <label htmlFor="txtAge" className="col-sm-2 col-form-label">Author</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="txtAge" required
                                        onChange={event => setAuthorDetails({ ...getAuthorDetails, name: event.target.value })} value={getAuthorDetails.name}

                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={() => updateChanges(getAuthorDetails)}>Save changes</Button>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>

            </Modal>
        </div>
    )
}
