import Cookies from 'js-cookie';
import React from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { GetToken } from '../utils/GetToken';
import html2canvas from 'html2canvas';
import { Toast, ToastContainer } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export const EditQuoteWallpaper = ({ show, onHide, value }) => {

    console.log(value);

    const divRef = useRef(null);

    const [containerScale, setContainerScale] = useState(1)
    const [containerSizeMode, setContainerSizeMode] = useState('desktop')
    const [containerSizeHight, setContainerSizeHight] = useState('720')
    const [containerSizeWidth, setContainerSizeWidth] = useState('1280')
    const [fontFamily, setFontFamily] = useState("'Lobster'");
    const [backgroundImage, setBackgroundImage] = useState('https://quotes.ansarimohammad.com/bg/thumb/1.jpg');
    const [fontSize, setFontSize] = useState(40);
    const [textAlign, setTextAlign] = useState('left');
    const [textColor, setTextColor] = useState('#ffffff');
    const [textBackgroundColor, setTextBackgroundColor] = useState("#ffffff");
    const [textAlphaColor, setTextAlphaColor] = useState(0.3);

    useEffect(() => {
        getWallpaperData()
    }, [value])

    const getWallpaperData = () => {
        setContainerSizeMode(value.layout)
        setBackgroundImage(value.BGImage)
        setContainerSizeHight(value.height)
        setContainerSizeWidth(value.width)
        setFontFamily(value.textStyle)
        setFontSize(value.textSize)
        setTextAlign(value.textAlign)
        setTextColor(value.textColor)
        setTextBackgroundColor(value.textBackground)
        setTextAlphaColor(value.textBGTransparency)
    }


    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')

    const ToastMessage = ({ show, onClose, message }) => {
        return (
            <Toast show={show} onClose={onClose}>
                <Toast.Header>
                    <strong className='me-auto'>{message.cat}</strong>
                </Toast.Header>
                <Toast.Body>
                    {message.content == "login" ?
                        <span>
                            user need to login. Click <Link to="/login">login</Link> to proceed.
                        </span>
                        :
                        message.content
                    }
                </Toast.Body>
            </Toast>
        )
    }

    const handleShowToast = message => {
        setToastMessage(message)
        setShowToast(true)

        // Hide the toast after a certain duration
        setTimeout(() => {
            setShowToast(false)
        }, 5000) // 3 seconds
    }



    const handleContainerSizeChange = (event) => {
        setContainerSizeMode(event.target.value);
        if (event.target.value == "desktop") {
            setContainerSizeHight("720");
            setContainerSizeWidth("1280");
        }
        else if (event.target.value == "desktopHD") {
            setContainerSizeHight("1080");
            setContainerSizeWidth("1920");
        }
        else if (event.target.value == "desktopFHD") {
            setContainerSizeHight("2160");
            setContainerSizeWidth("3840");
        }
        else if (event.target.value == "mobile") {
            setContainerSizeHight("800");
            setContainerSizeWidth("480");
        }
        else if (event.target.value == "mobileHD") {
            setContainerSizeHight("1280");
            setContainerSizeWidth("720");
        }
        else if (event.target.value == "mobileFHD") {
            setContainerSizeHight("1920");
            setContainerSizeWidth("1080");
        }
        else {
            setContainerSizeHight("500");
            setContainerSizeWidth("500");
        }


    };


    const handleContainerScaleChange = (event) => {
        setContainerScale(event.target.value);
    };

    const handleFontSizeChange = (event) => {
        setFontSize(parseInt(event.target.value, 10));
    };

    const handleTextAlignChange = (event) => {
        setTextAlign(event.target.value);
    };

    const handleExportClick = () => {
        if (Cookies.get('user') == undefined) {

            let message = { cat: 'Download Image', content: 'login' }
            handleShowToast(message)
        }
        else {
            GetToken()
            setContainerScale(1);
            if (divRef.current) {
                const container = divRef.current;
                let CurrentScale = containerScale;
                const backgroundImageForCanvas = new Image();
                backgroundImageForCanvas.crossOrigin = "Anonymous"
                backgroundImageForCanvas.src = backgroundImage;
                backgroundImageForCanvas.onload = async () => {
                    setContainerScale(CurrentScale);
                    container.style.backgroundImage = `url(${backgroundImage})`;
                    html2canvas(container, { useCORS: true }).then((canvas) => {
                        const dataUrlOriginal = canvas.toDataURL('image/png');
                        const a = document.createElement('a');
                        a.href = dataUrlOriginal;
                        a.download = 'exported_image.png';
                        a.click();
                    });
                }
            }
        }

    };
    return (
        <Modal show={show} onHide={onHide} dialogClassName="full-width-height-modal">
            {/* {loading && <div className='loader' title="Please Waiting"></div>} */}
            <Modal.Body className="full-screen-modal-content" >

                <ToastContainer
                    style={{ top: '20px', right: '20px', position: 'fixed' }}
                    className='p-3'
                >
                    <ToastMessage
                        show={showToast}
                        onClose={() => setShowToast(false)}
                        message={toastMessage}
                    />
                </ToastContainer>
                <div className='container-fluid update-wallpaper'>
                    <div className='row'>

                        <div className='col-lg-3 order-lg-1 order-2 px-0' >

                            <div className="ScrollableLeftPanelLeft container pt-3">
                                {/* <div className="row">
                                    <div className="col">
                                        <h2 className='mt-3 text-center'>Generate Wallpaper</h2>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col">
                                        Zoom
                                        {parseFloat(containerScale * 100).toFixed(0)} %
                                    </div>
                                    <div className="col">
                                        <input id="rangeScale" type="range" className="form-range mt-2"
                                            onChange={handleContainerScaleChange} value={containerScale}
                                            min="0.2" max="1" step="0.01" />
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className="row">
                                    <div className="col">
                                        Layout :
                                        {containerSizeWidth} x {containerSizeHight}
                                    </div>
                                    <div className="col">
                                        <select id="ddlFontSize" className="form-select form-select-sm container-size"
                                            onChange={handleContainerSizeChange} value={containerSizeMode}
                                        >
                                            <option value="desktop">Desktop</option>
                                            <option value="desktopHD">Desktop HD</option>
                                            <option value="mobile">Mobile</option>
                                            <option value="mobileHD">Mobile HD</option>
                                        </select>
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className="row">
                                    <div className="col">
                                        Text Style
                                    </div>
                                    <div className="col">
                                        <div className='d-flex'>
                                            {/* <select id="ddlFontFamily" className="form-select form-select-sm"
                                            onChange={handleFontFamilyChange} value={fontFamily}
                                            >
                                                <option value="'Open Sans', sans-serif">Open Sans</option>
                                                <option value="'Roboto', sans-serif">Roboto</option>
                                                <option value="'Lobster', sans-serif">Lobster</option>
                                                <option value="'Courier New', monospace">Courier New</option>
                                                <option value="'Arial'">Arial</option>
                                            </select> */}
                                            <select id="ddlFontAlign" className="form-select form-select-sm"
                                            onChange={handleTextAlignChange} value={textAlign}
                                            >
                                                <option value="left">Left</option>
                                                <option value="center">Center</option>
                                                <option value="justify">Justify</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className="row">
                                    <div className="col">
                                        Text Size
                                    </div>
                                    <div className="col">
                                        <input id="rangeFontSize" type="range" className="form-range"
                                            onChange={handleFontSizeChange} value={fontSize}
                                            min="20" max="100" step="1" />
                                    </div>
                                </div>
                                {/* <hr className='my-2' />
                                <div className="row">
                                    <div className="col">
                                        Color & Background
                                    </div>
                                    <div className="col">
                                        <div className='d-flex'>
                                            <input id="clrTextColor" type="color" className="form-control form-control-color w-50"
                                            onChange={handleTextColorChange} value={textColor} 

                                            />
                                            <input id="clrTextBackground" type="color" className="form-control form-control-color w-50 ms-1"
                                            onChange={handleTextBackgroundColorChange} value={textBackgroundColor} 
                                            />

                                        </div>
                                    </div>
                                </div> */}
                                {/* <hr className='my-2' />
                                <div className="row">
                                    <div className="col">
                                        Text Transparency
                                    </div>
                                    <div className="col">
                                        <input id="rangeOpacity" type="range" className="form-range"
                                            onChange={handleTextAlphaChange} value={textAlphaColor} 
                                            min="0" max="1" step="0.01" />
                                    </div>
                                </div> */}
                                {/* <hr className='my-2' />
                                <div className="row">
                                    <div className="col-12">
                                        <div className='BackgroundImageBox-container'>
                                            {imageList.map((item, index) => (
                                                <div key={index}
                                                    className='BackgroundImageBox'
                                                    onClick={() => { setBackgroundImage(item.image) }}
                                                    style={{
                                                        backgroundImage: 'url(' + item.thumbnail + ')'
                                                    }}
                                                ></div>
                                            ))}
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col'>
                                        <hr className='my-2' />
                                        <Button onClick={handleExportClick} className='mx-1'>Download Wallpaper</Button>
                                        <Button variant="secondary" onClick={onHide} className='mx-1'> Close </Button>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-9 order-lg-2 order-1'>
                            <div className='ScrollableLeftPanelRight' style={{ position: 'relative', marginTop: '10px' }} >
                                <div className="box-container"
                                    ref={divRef}
                                    style={{
                                        width: `${containerSizeWidth}px`,
                                        height: `${containerSizeHight}px`,
                                        backgroundImage: `url(${backgroundImage})`,
                                        scale: `${containerScale}`
                                    }}
                                >
                                    <div style={{ maxWidth: '80%' }}>
                                        <p
                                            style={{
                                                padding: '10px',
                                                fontSize: `${fontSize}px`,
                                                textAlign: `${textAlign}`,
                                                color: `${textColor}`,
                                                fontFamily: `${fontFamily}`,
                                                background: `rgba(${parseInt(textBackgroundColor.slice(1, 3), 16)},${parseInt(textBackgroundColor.slice(3, 5), 16)},${parseInt(textBackgroundColor.slice(5, 7), 16)},${textAlphaColor})`
                                            }}
                                        >
                                            {value.resultQuote[0]?.quote}
                                        </p>
                                        <p className='text-center mb-0'>
                                            <span
                                                style={{
                                                    padding: '5px 10px',
                                                    background: 'rgba(0,0,0,0.7)',
                                                    color: '#ffffff',
                                                    fontFamily: `${fontFamily}`,
                                                    fontSize: `${fontSize * 0.6}px`,
                                                }}
                                            >{
                                                    value.resultAuthor[0].name 
                                                }
                                                    {/* == 'By Self' ? value.author : value.resultAuthor[0].name */}
                                            </span>
                                        </p>

                                        <p className='text-center m-0'>
                                            <span
                                                style={{
                                                    position: 'relative',
                                                    top: '40px',
                                                    padding: '3px 10px',
                                                    background: 'rgba(0,0,0,0.4)',
                                                    color: '#ffffff',
                                                    fontSize: `${fontSize * 0.3}px`,
                                                }}
                                            >quotes.ansarimohammad.com</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
