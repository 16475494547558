import React, { useEffect, useRef, useState } from 'react'
import { Modal, Button, Row } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesStacked, faClose, faFileExport } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Cookies from 'js-cookie';
import { GetToken } from '../utils/GetToken';
import { Toast, ToastContainer } from 'react-bootstrap'
import { Link } from 'react-router-dom';

export const AddQuoteWallpaper = ({ show, onHide, value }) => {
    // console.log(value);
    const baseURL = process.env.REACT_APP_BACKEND_URL
    const [loading, setLoading] = useState(false);

    const divRef = useRef(null);

    const imageList = []

    for (let i = 1; i <= 100; i++) { // Change 10 to the number of images you want
        const imageName = `${i}.jpg`;
        const imageObject = {
            thumbnail: `https://quotes.ansarimohammad.com/bg/thumb/${imageName}`,
            image: `https://quotes.ansarimohammad.com/bg/orginal/${imageName}`,
        };
        imageList.push(imageObject);
    }

    const [containerScale, setContainerScale] = useState(1)
    const [containerSizeMode, setContainerSizeMode] = useState("desktop")
    const [containerSizeHight, setContainerSizeHight] = useState("720")
    const [containerSizeWidth, setContainerSizeWidth] = useState("1280")
    const [fontFamily, setFontFamily] = useState("'Lobster'");
    const [backgroundImage, setBackgroundImage] = useState(imageList[0].thumbnail);
    const [fontSize, setFontSize] = useState(40);
    const [textAlign, setTextAlign] = useState("left");
    const [textColor, setTextColor] = useState("#ffffff");
    const [textBackgroundColor, setTextBackgroundColor] = useState("#000000");
    const [textAlphaColor, setTextAlphaColor] = useState(0.3);



    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')

    const ToastMessage = ({ show, onClose, message }) => {
        return (
            <Toast show={show} onClose={onClose}>
                <Toast.Header>
                    <strong className='me-auto'>{message.cat}</strong>
                </Toast.Header>
                <Toast.Body>
                    {message.content == "login" ?
                        <span>
                            user need to login. Click <Link to="/login">login</Link> to proceed.
                        </span>
                        :
                        message.content
                    }
                </Toast.Body>
            </Toast>
        )
    }

    const handleShowToast = message => {
        setToastMessage(message)
        setShowToast(true)

        // Hide the toast after a certain duration
        setTimeout(() => {
            setShowToast(false)
        }, 5000) // 3 seconds
    }


    const handleContainerSizeChange = (event) => {
        setContainerSizeMode(event.target.value);
        if (event.target.value == "desktop") {
            setContainerSizeHight("720");
            setContainerSizeWidth("1280");
        }
        else if (event.target.value == "desktopHD") {
            setContainerSizeHight("1080");
            setContainerSizeWidth("1920");
        }
        else if (event.target.value == "desktopFHD") {
            setContainerSizeHight("2160");
            setContainerSizeWidth("3840");
        }
        else if (event.target.value == "mobile") {
            setContainerSizeHight("800");
            setContainerSizeWidth("480");
        }
        else if (event.target.value == "mobileHD") {
            setContainerSizeHight("1280");
            setContainerSizeWidth("720");
        }
        else if (event.target.value == "mobileFHD") {
            setContainerSizeHight("1920");
            setContainerSizeWidth("1080");
        }
        else {
            setContainerSizeHight("500");
            setContainerSizeWidth("500");
        }


    };

    const handleContainerScaleChange = (event) => {
        setContainerScale(event.target.value);
    };


    const handleFontFamilyChange = (event) => {
        setFontFamily(event.target.value);
    };

    const handleFontSizeChange = (event) => {
        setFontSize(parseInt(event.target.value, 10));
    };
    const handleTextAlignChange = (event) => {
        setTextAlign(event.target.value);
    };

    const handleTextColorChange = (event) => {
        setTextColor(event.target.value);
    };

    const handleTextBackgroundColorChange = (event) => {
        setTextBackgroundColor(event.target.value);
    }


    const handleTextAlphaChange = (event) => {
        setTextAlphaColor(event.target.value);
    };

    const handleBackgroundImageChange = (event) => {
        setBackgroundImage(event.target.value);
    };

    const handleExportClick = () => {
        if (Cookies.get('user') == undefined) {
            let message = { cat: 'Download Image', content: 'login' }
            handleShowToast(message)
        }
        else {
            GetToken()
            setLoading(true);
            setContainerScale(1);
            if (divRef.current) {
                const container = divRef.current;
                let CurrentScale = containerScale;
                const backgroundImageForCanvas = new Image();
                backgroundImageForCanvas.crossOrigin = "Anonymous"
                backgroundImageForCanvas.src = backgroundImage;
                backgroundImageForCanvas.onload = async () => {
                    setContainerScale(CurrentScale);
                    container.style.backgroundImage = `url(${backgroundImage})`;
                    html2canvas(container, { useCORS: true }).then((canvas) => {
                        // const dataUrl = canvas.toDataURL('image/png');
                        // saveImageOnServer(dataUrl);



                        // Create a thumbnail
                        const canvasThumbnail = document.createElement('canvas');
                        const ctx = canvasThumbnail.getContext('2d');
                        const maxThumbnailWidth = 640;
                        const maxThumbnailHeight = 360;

                        // Calculate new dimensions to maintain aspect ratio
                        let newWidth = canvas.width;
                        let newHeight = canvas.height;
                        if (canvas.width > maxThumbnailWidth) {
                            newWidth = maxThumbnailWidth;
                            newHeight = (canvas.height / canvas.width) * maxThumbnailWidth;
                        }
                        if (newHeight > maxThumbnailHeight) {
                            newHeight = maxThumbnailHeight;
                            newWidth = (canvas.width / canvas.height) * maxThumbnailHeight;
                        }

                        canvasThumbnail.width = newWidth;
                        canvasThumbnail.height = newHeight;
                        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newWidth, newHeight);
                        const dataUrlThumbnail = canvasThumbnail.toDataURL('image/png');

                        // Save both the original and thumbnail images
                        saveImageOnServer(dataUrlThumbnail);

                        // Save the original image
                        const dataUrlOriginal = canvas.toDataURL('image/png');
                        const a = document.createElement('a');
                        a.href = dataUrlOriginal;
                        a.download = 'exported_image.png';
                        a.click();


                    });
                }
            }
        }

    };

    const saveImageOnServer = async (dataUrlThumbnail) => {

        const imageThumbnailData = {
            quoteId: value.id,
            image: dataUrlThumbnail,
            layout: containerSizeMode,
            height: containerSizeHight,
            width: containerSizeWidth,
            textStyle: fontFamily,
            textAlign: textAlign,
            textSize: fontSize,
            textColor: textColor,
            textBackground: textBackgroundColor,
            textBGTransparency: textAlphaColor,
            BGImage: backgroundImage
        };

        await axios.post(`${baseURL}quote/create-wallpaper`, imageThumbnailData)
            .then((response) => {
                console.log('Thumbnail Image saved on the server:', response.data);
            })
            .catch((error) => {
                console.error('Error saving the thumbnail image:', error);
            });
        setLoading(false);

    };

    return (
        <Modal show={show} onHide={onHide} dialogClassName="full-width-height-modal">
            {loading && <div className='loader' title="Please Waiting"></div>}
            <Modal.Body className="full-screen-modal-content" >

                <ToastContainer
                    style={{ top: '20px', right: '20px', position: 'fixed' }}
                    className='p-3'
                >
                    <ToastMessage
                        show={showToast}
                        onClose={() => setShowToast(false)}
                        message={toastMessage}
                    />
                </ToastContainer>
                <div className='container-fluid'>
                    <div className='row'>

                        <div className='col-lg-3 order-lg-1 order-2 px-0 mt-2' >

                            <div className="ScrollableLeftPanelLeft container">

                                <div className="row">
                                    <div className="col">
                                        Zoom {parseFloat(containerScale * 100).toFixed(0)} %
                                    </div>
                                    <div className="col">
                                        <input id="rangeScale" type="range" className="form-range mt-2" onChange={handleContainerScaleChange} value={containerScale} min="0.2" max="1" step="0.01" />
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className="row">
                                    <div className="col">
                                        Layout : {containerSizeWidth} x {containerSizeHight}
                                    </div>
                                    <div className="col">
                                        <select id="ddlFontSize" className="form-select form-select-sm container-size" onChange={handleContainerSizeChange} value={containerSizeMode}>
                                            <option value="desktop">Desktop</option>
                                            <option value="desktopHD">Desktop HD</option>
                                            {/* <option value="desktopFHD">Desktop FHD</option> */}
                                            <option value="mobile">Mobile</option>
                                            <option value="mobileHD">Mobile HD</option>
                                            {/* <option value="mobileFHD">Mobile FHD</option> */}
                                        </select>
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className="row">
                                    <div className="col">
                                        Text Style
                                    </div>
                                    <div className="col">
                                        <div className='d-flex'>
                                            <select id="ddlFontFamily" className="form-select form-select-sm" onChange={handleFontFamilyChange} value={fontFamily}>
                                                <option value="'Open Sans', sans-serif">Open Sans</option>
                                                <option value="'Roboto', sans-serif">Roboto</option>
                                                <option value="'Lobster', sans-serif">Lobster</option>
                                                <option value="'Courier New', monospace">Courier New</option>
                                                <option value="'Arial'">Arial</option>
                                            </select>
                                            <select id="ddlFontAlign" className="form-select form-select-sm ms-1" onChange={handleTextAlignChange} value={textAlign}>
                                                <option value="left">Left</option>
                                                <option value="center">Center</option>
                                                <option value="justify">Justify</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className="row">
                                    <div className="col">
                                        Text Size
                                    </div>
                                    <div className="col">
                                        <input id="rangeFontSize" type="range" className="form-range" onChange={handleFontSizeChange} value={fontSize} min="20" max="100" step="1" />
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className="row">
                                    <div className="col">
                                        Color & Background
                                    </div>
                                    <div className="col">
                                        <div className='d-flex'>
                                            <input id="clrTextColor" type="color" className="form-control form-control-color w-50" onChange={handleTextColorChange} value={textColor} />
                                            <input id="clrTextBackground" type="color" className="form-control form-control-color w-50 ms-1" onChange={handleTextBackgroundColorChange} value={textBackgroundColor} />

                                        </div>
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className="row">
                                    <div className="col">
                                        Text Transparency
                                    </div>
                                    <div className="col">
                                        <input id="rangeOpacity" type="range" className="form-range" onChange={handleTextAlphaChange} value={textAlphaColor} min="0" max="1" step="0.01" />
                                    </div>
                                </div>
                                <hr className='my-2' />
                                <div className="row">
                                    <div className="col-12">
                                        <div className='BackgroundImageBox-container'>
                                            {imageList.map((item, index) => (
                                                <div key={index}
                                                    className='BackgroundImageBox'
                                                    onClick={() => { setBackgroundImage(item.image) }}
                                                    style={{
                                                        backgroundImage: 'url(' + item.thumbnail + ')'
                                                    }}
                                                ></div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='container'>
                                <hr className='my-2' />

                                <div className='row'>
                                    <div className='col'>
                                        <Button onClick={handleExportClick} className='me-1 btn-sm'>Download Wallpaper</Button>
                                        <Link to={`/wallpaper/quoteId/${value.id}`}
                                            className='btn btn-secondary btn-sm me-1'>
                                            {/* <FontAwesomeIcon
                                                icon={faBoxesStacked}
                                                title="Similar Wallpaper"
                                                className=''
                                            /> */}
                                            {/* Similar Wallpaper */}
                                            {/* <FontAwesomeIcon
                                                icon={faBoxesStacked}
                                                title="Similar Wallpaper"
                                                className=''
                                            /> */}
                                            Similar Wallpaper
                                        </Link>
                                        <Button variant="secondary" onClick={onHide} className=' btn-sm'>
                                            <FontAwesomeIcon
                                                icon={faClose}
                                                title="Similar Wallpaper"
                                                className=''
                                            />
                                        </Button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-9 order-lg-2 order-1'>
                            <div className='ScrollableLeftPanelRight' style={{ position: 'relative', marginTop: '10px' }} >
                                <div className="box-container" ref={divRef}
                                    style={{
                                        width: `${containerSizeWidth}px`,
                                        height: `${containerSizeHight}px`,
                                        backgroundImage: `url(${backgroundImage})`,
                                        scale: `${containerScale}`
                                    }}
                                >
                                    <div style={{ maxWidth: '80%' }}>
                                        <p
                                            style={{
                                                padding: '10px',
                                                fontSize: `${fontSize}px`,
                                                textAlign: `${textAlign}`,
                                                color: `${textColor}`,
                                                fontFamily: `${fontFamily}`,
                                                background: `rgba(${parseInt(textBackgroundColor.slice(1, 3), 16)},${parseInt(textBackgroundColor.slice(3, 5), 16)},${parseInt(textBackgroundColor.slice(5, 7), 16)},${textAlphaColor})`
                                            }}>
                                            {value.quote}
                                        </p>
                                        <p className='text-center mb-0'>
                                            <span
                                                style={{
                                                    padding: '5px 10px',
                                                    background: 'rgba(0,0,0,0.7)',
                                                    color: '#ffffff',
                                                    fontFamily: `${fontFamily}`,
                                                    fontSize: `${fontSize * 0.6}px`,
                                                }}
                                            >{
                                                    value.author == 'By Self' ? value.user : value.author
                                                }</span>
                                        </p>

                                        <p className='text-center m-0'>
                                            <span
                                                style={{
                                                    position: 'relative',
                                                    top: '40px',
                                                    padding: '3px 10px',
                                                    background: 'rgba(0,0,0,0.4)',
                                                    color: '#ffffff',
                                                    fontSize: `${fontSize * 0.3}px`,
                                                }}
                                            >quotes.ansarimohammad.com</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
