import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Toast, ToastContainer } from 'react-bootstrap'
import ReactGA from 'react-ga';
const Feedback = () => {

  useEffect(() => {
    let pageName = 'Feedback';
    ReactGA.pageview(pageName);
    document.title = pageName + ' | ' + process.env.REACT_APP_Name;
  }, []);

  const baseURL = process.env.REACT_APP_BACKEND_URL + 'feedback'
  const [category, setCategory] = useState('General')
  const [email, setEmail] = useState('')
  const [feedback, setFeedback] = useState('')

  const [showToast, setShowToast] = React.useState(false)
  const [showToastContent, setShowToastContent] = React.useState(false)
  const handleCloseToast = () => {
    setShowToast(false)
  }
  const handleShowToast = () => {
    setShowToast(true)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (email != "" && feedback != "") {
      let apiData = {
        type: category,
        email,
        feedback
      }

      await axios.post(baseURL, apiData).then(res => {
        // setCategory('')
        setEmail('')
        setFeedback('')
        handleShowToast()
        setShowToastContent('Feedback sent successfully.')
      })
    }
    else {
      setShowToastContent('Enter the required field.')

    }
  }

  return (
    <div>
      <Toast
        show={showToast}
        onClose={handleCloseToast}
        style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 9999 }}
      >
        <Toast.Header>
          <strong className='me-auto'>Notification</strong>
          <small>Just now</small>
        </Toast.Header>
        <Toast.Body>{showToastContent}</Toast.Body>
      </Toast>

      <div className='container-fluid pt-5'>
        <div className='row'>
          <div className='col-md-6'>
            <h3>Send Feedback to Admin</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className=' mt-3' htmlFor='category'>Feedback Type</Form.Label>
                <Form.Control
                  as='select'
                  id='category'
                  className="form-select"
                  value={category}
                  onChange={e => setCategory(e.target.value)}
                >
                  <option value='General'>General</option>
                  <option value='Query'>Query</option>
                  <option value='Suggestion'>Suggestion</option>
                  <option value='Error'>Error Reporting</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className=' mt-3' htmlFor='email'>Email</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  id='email'
                  placeholder='Enter your email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className=' mt-3' htmlFor='Query'>Message</Form.Label>
                <Form.Control
                  autoComplete='off'
                  as='textarea'
                  name='Query'
                  id='Query'
                  placeholder='Enter your Feedback'
                  value={feedback}
                  onChange={e => setFeedback(e.target.value)}
                />
              </Form.Group>
              <Button type='submit' className='mt-3'>
                Submit Feedback
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feedback
