import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState, useRef } from 'react'
import { GetToken } from '../utils/GetToken'
import { CommonQuoteList } from '../Components/CommonQuoteList'
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';

const QuoteByAuthor = () => {
  const baseURL = process.env.REACT_APP_BACKEND_URL
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // const pageRef = useRef(1);
  const [getQuoteList, setQuoteList] = useState([])
  let page = 0;
  let startNextDownload = true;

  const handleObserver = (entities) => {
    const target = entities[0];
    if (startNextDownload) {
      if (page == 0) {
        page = 1;
        getData();
      }
      else {
        if (target.isIntersecting) {
          page++
          getData(); // Load more data when the target element is in view
        }
      }
    }
  };

  useEffect(() => {
    page = 0;
    const observer = new IntersectionObserver(handleObserver, {
      root: null, // Use the viewport as the root
      rootMargin: '10px', // No margin
      threshold: 1.0, // Fully visible
    });

    const target = document.querySelector('.scroll-trigger'); // Element at the bottom of the content
    if (target) {
      observer.observe(target);
    }
    return () => observer.disconnect(); // Cleanup when unmounting
  }, [id]);


  const getData = async () => {
    setLoading(true);
    if (Cookies.get('user') != undefined) GetToken()
    await axios
      .get(baseURL + 'quote/author?authorId=' + id + '&page=' + page)
      .then(response => {
        console.log(response.data[0].resultCat[0].name);
        let pageName = 'Author - ' + response.data[0].resultAuthor[0].name;
        ReactGA.pageview(pageName);
        document.title = pageName + ' | ' + process.env.REACT_APP_Name;
        if (page == 1) {
          setQuoteList(response.data);
        }
        else {
          setQuoteList((getQuoteList) => [...getQuoteList, ...response.data]);
        }
        if (response.data.length < 10) {
          startNextDownload = false;
        }
      })
      .catch(error => {
        console.log(error.response.data.message)
      })
    setLoading(false);
    // pageRef.current++;
  }

  return (
    <div className='container-fluid py-3'>      
      <CommonQuoteList getQuoteList={getQuoteList} />
      {loading && <div className='loader' title="Please Waiting"></div>}
      <div className="scroll-trigger"></div>
    </div>
  )
}

export default QuoteByAuthor
