import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "react-bootstrap/Button";
import { Toast, ToastContainer } from 'react-bootstrap';

export const ForgetPasswordVerification = () => {
    const { id } = useParams();
    const [getMessage, setMessage] = useState('');
    const baseURL = process.env.REACT_APP_BACKEND_URL
    const [password, setPassword] = useState("");
    const [captchaValueForgetPassword, setCaptchaValueForgetPassword] = useState(null);


    const ChangePassword = async () => {
        if (!captchaValueForgetPassword) {
            handleShowToast({ cat: 'Forget Password', content: 'Please complete the CAPTCHA.' })
            return;
        }
        const data = {
            token: id,
            password
        }
        // console.log(2222, data);
        await axios.post(baseURL + 'employee/change-forget-password', data)
            .then(response => {
                console.log(response.data.message);
                setMessage(response.data.message);
            })
            .catch(error => {
                setMessage(error.response.data.message);
                console.log(error.response.data.message);
            })

    }

    const handleChangePassword = (event) => {
        event.preventDefault();
        ChangePassword();
    }


    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')

    const ToastMessage = ({ show, onClose, message }) => {
        return (
            <Toast show={show} onClose={onClose}>
                <Toast.Header>
                    <strong className='me-auto'>{message.cat}</strong>
                </Toast.Header>
                <Toast.Body>{message.content}</Toast.Body>
            </Toast>
        )
    }

    const handleShowToast = message => {
        setToastMessage(message)
        setShowToast(true)

        // Hide the toast after a certain duration
        setTimeout(() => {
            setShowToast(false)
        }, 5000) // 3 seconds
    }


    return (
        <div>
            <ToastContainer
                style={{ top: '20px', right: '20px', position: 'fixed' }}
                className='p-3'
            >
                <ToastMessage
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    message={toastMessage}
                />

            </ToastContainer>
            <div className="container-fluid login-bg">
                <div className="d-flex justify-content-center align-items-center " style={{ height: 'calc(100vh - 56px)' }}>
                    <div className="bg-dark p-2 text-dark bg-opacity-25 rounded p-4 d-block">
                        <Form onSubmit={handleChangePassword} >

                            {/* <div className="text-center text-white h3 ">
  <img src="./logo512.png" style={{ height: '100px' }} />
  <br />Fuel Your Inspiration, Sign In
</div> */}

                            <Form.Group>
                                {/* <Form.Label htmlFor="password">Password</Form.Label> */}
                                <Form.Control
                                    className="mt-3"
                                    autoComplete="off"
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>
                            <ReCAPTCHA className="my-3"
                                sitekey="6LePiWEoAAAAAD_hFVsbKYyeWPhPj7ArAtrmN5yg"
                                onChange={(value) => setCaptchaValueForgetPassword(value)}
                            />

                            <Button type="submit" className="w-100 mb-3 ">Login</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
