import axios from 'axios';
import React, { useEffect, useState } from 'react'

export const FeedbackList = () => {
    const baseURL = process.env.REACT_APP_BACKEND_URL + "feedback";
    const [tableData, setTable] = useState([]);

    const getData = async () => {
        await axios.get(baseURL).then((response) => {
          setTable(response.data);
        })
      }
      
  const formatDate = (str) => {
    let date = new Date(str);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hour = ("0" + date.getHours()).slice(-2);
    let min = ("0" + date.getMinutes()).slice(-2);
    return [day, month, date.getFullYear()].join("/") + " " + hour + ":" + min;
  }

    useEffect(() => {
        getData();
      }, []);

  return (
    <div><table className="table table-striped">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Category</th>
        <th scope="col">Email</th>
        <th scope="col">Feedback</th>
        <th scope="col">Date</th>
      </tr>
    </thead>
    <tbody>
    {
            tableData.map((item, index) => {
              return (
                <tr key={item._id}>
                  <th>{index + 1}</th>
                  <td>{item.type}</td>
                  <td>{item.email}</td>
                  <td>{item.feedback}</td>
                  <td>{formatDate(new Date(item.createdAt))}</td>
                </tr>
              )
            })
          }
</tbody>
</table>

    </div>
  )
}
