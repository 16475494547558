import axios from 'axios';
import React, { useState, useEffect } from 'react'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie';
import AddQuotePopup from './Home/AddQuotePopup';
import { GetToken } from '../utils/GetToken';

export const QuoteByMe = () => {
  GetToken();
  const baseURL = process.env.REACT_APP_BACKEND_URL;

  const [getQuote, setQuote] = useState([]);
  const [getQuoteList, setQuoteList] = useState([]);
  const [getCategoryList, setCategoryList] = useState([]);
  const [getAuthorList, setAuthorList] = useState([]);

  const getData = async () => {
    await axios.get(baseURL + "quote")
      .then((response) => {
        setQuoteList(response.data);
      })
    // .catch((error) => {
    //   console.log(error.response.data.message);
    // });
  }



  useEffect(() => {
    getCategories();
    getAuthors();
    getData();
  }, []);


  const editEmployee = async (id) => {
    await axios.get(baseURL + "quote/" + id).then((response) => {
      setQuote({
        id: response.data._id,
        author: response.data.author,
        quote: response.data.quote,
        category: response.data.category,
        status: response.data.status
      });

      handleShow();
    })


  }


  const getCategories = async () => {
    await axios.get(baseURL + "category")
      .then(res => {
        setCategoryList(res.data);
      })
  }

  
  const getAuthors = async () => {
    await axios.get(baseURL + "author")
      .then(res => {
        setAuthorList(res.data);
      })
  }



  const getDate = (str) => {
    let date = new Date(str);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join("-");
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const deleteEmployee = async (id) => {
    await axios.delete(baseURL + "quote/" + id);
    getData();
  }


  const formatDate = (str) => {
    let date = new Date(str);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hour = ("0" + date.getHours()).slice(-2);
    let min = ("0" + date.getMinutes()).slice(-2);
    return [day, month, date.getFullYear()].join("/") + " " + hour + " : " + min;
  }

  const updateChanges = async (data) => {
    await axios.put(baseURL + "quote/" + data.id, data)
      .then(res => {
        getData();
        handleClose();
      })
  }


  return (
    <div className='container-fluid py-3 bg-white'>

      <AddQuotePopup onInsert={getData} />

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Author</th>
            <th scope="col" width="700">Quote</th>
            <th scope="col">Category</th>
            <th scope="col">Date</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {
            getQuoteList.map((item, index) => {
              return (
                <tr key={index}>
                  <th>{index + 1}</th>
                  <td>{item.resultAuthor[0].name}</td>
                  <td>{item.quote}</td>
                  <td>{item.resultCat[0].name}</td>
                  <td>{formatDate(new Date(item.createdAt))}</td>
                  <td>{item.status}</td>
                  <td>
                    <button className="btn btn-primary  mx-1" onClick={() => editEmployee(item._id)} title="Edit">E</button>
                    <button className="btn btn-secondary mx-1" onClick={() => deleteEmployee(item._id)} title="Delete">X</button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>

      <Modal show={show} onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>Update Quote</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form >
            <div className="modal-body">
              <div className="mb-3 row">
                <label htmlFor="txtName" className="col-sm-2 col-form-label">Author</label>
                <div className="col-sm-10">
                <select className="form-select" aria-label="Default select example" onChange={event => setQuote({ ...getQuote, author: event.target.value })} value={getQuote.author}>
                    <option></option>
                    {
                      getAuthorList?.map((author, index) => {
                        return (
                          <option key={index} value={author._id}>{author.name}</option>
                        )
                      })
                    }
                  </select>
                  {/* <input type="text" className="form-control" id="txtName" required
                    onChange={event => setQuote({ ...getQuote, author: event.target.value })} value={getQuote.author} 
                  />*/}
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="txtAge" className="col-sm-2 col-form-label">Category</label>
                <div className="col-sm-10">
                  <select className="form-select" aria-label="Default select example" onChange={event => setQuote({ ...getQuote, category: event.target.value })} value={getQuote.category}>
                    {
                      getCategoryList?.map((category, index) => {
                        return (
                          <option key={index} value={category._id}>{category.name}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="txtEmail" className="col-sm-2 col-form-label">Quote</label>
                <div className="col-sm-10">

                  <textarea className="form-control" id="txtAddress" required rows="3"
                    onChange={event => setQuote({ ...getQuote, quote: event.target.value })} value={getQuote.quote}
                  >
                  </textarea>
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="txtAddress" className="col-sm-2 col-form-label">Status</label>
                <div className="col-sm-10">
                  <select className="form-select" aria-label="Default select example" onChange={event => setQuote({ ...getQuote, status: event.target.value })} value={getQuote.status}>
                    <option>Public</option>
                    <option>Private</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => updateChanges(getQuote)}>Save changes</Button>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>

      </Modal>

    </div>
  )
}
