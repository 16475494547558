import axios from 'axios';
import React, { useState, useEffect } from 'react'
import AddEmployeePopup from './Home/AddEmployeePopup';
// import GetToken from '../utils/GetToken';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie';
import { GetToken } from '../utils/GetToken';
import moment from 'moment/moment';

export const UserList = () => {

  GetToken();
  const baseURL = process.env.REACT_APP_BACKEND_URL + "employee";

  const [tableData, setTable] = useState([]);
  const [userData, setUser] = useState([]);
  const [getUserImage, setUserImage] = useState();

  const getData = async () => {
    await axios.get(baseURL).then((response) => {
      setTable(response.data);
    })
  }




  useEffect(() => {
    getData();
  }, []);

  const deleteData = async (id) => {
    await axios.delete(baseURL + "/" + id);
    getData();
  }

  const editEmployee = async (id) => {
    await axios.get(baseURL + "/" + id).then((response) => {
      setUser({
        id,
        name: response.data.name,
        email: response.data.email,
        status: response.data.status,
        role: response.data.role,
        photo: response.data.photo,
      });
      handleShow();
    })


  }

  const getDate = (str) => {
    let date = new Date(str);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join("-");
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteEmployee = (id) => {
    deleteData(id);
  }

  const showPassword = (hashCode) => {
    console.log(hashCode);
  }

  const formatDate = (str) => {
    let date = new Date(str);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hour = ("0" + date.getHours()).slice(-2);
    let min = ("0" + date.getMinutes()).slice(-2);
    return [day, month, date.getFullYear()].join("/") + " " + hour + ":" + min;
  }

  const updateChanges = async (data) => {
    console.log(data, data.id, 10);

    await axios.put(baseURL + "/" + data.id, data)
      .then(res => {
        getData();
        handleClose();
      })
  }

  const setProfileImage = (event) => {
    try {

      const file = event.target.files[0];
      uploadFileToServer(file);
    } catch (error) {

    }
  }

  const uploadFileToServer = async (file) => {
    const formData = new FormData();
    formData.append("fileUpload", file);
    let fileName = moment().format("YYMMDDHHmmssSSS");
    formData.append("img_name", fileName);

    await axios.post(baseURL + "/image", formData)
      .then((response) => {
        console.log(response.data, 12);
        setUser({
          ...userData,
          photo: response.data.filename
        })
        // setUserImage(response.data.filename)
      })
      .catch((error) => {
        console.log(error, 13);
      });

  }




  return (
    <div>

      <AddEmployeePopup onInsert={getData} />

      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Email</th>
            <th scope="col">name</th>
            <th scope="col">Quotes</th>
            <th scope="col">Role</th>
            <th scope="col">Date</th>
            <th scope="col">Status</th>
            <th scope="col">Followers</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {
            tableData.map((item, index) => {
              return (
                <tr key={item._id}>
                  <th>{index + 1}</th>
                  <td>{item.email}</td>
                  <td>
                    {item.photo ?
                      <img src={process.env.REACT_APP_BACKEND_URL+ "uploads/" + item.photo} weight="50" height="50" alt={item.photo} />
                      : ''}
                    {item.name}</td>
                  <td>{item.quotes.length}</td>
                  <td>{item.role}</td>
                  <td>{formatDate(new Date(item.createdAt))}</td>
                  <td>{item.status}</td>
                  <td><a href='#'>24 Followers</a></td>
                  <td>
                    <button className="btn btn-primary  mx-1" onClick={() => editEmployee(item._id)}>Edit</button>
                    <button className="btn btn-secondary mx-1" onClick={() => deleteEmployee(item._id)}>Delete</button>
                    <button className="btn btn-secondary mx-1" onClick={() => showPassword(item.password)}>Password</button>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>




      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form >
            <div className="modal-body">
              <div className="mb-3 row">
                <label htmlFor="txtName" className="col-sm-2 col-form-label">Name</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" id="txtName" required
                    onChange={event => setUser({ ...userData, name: event.target.value })} value={userData.name}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="txtEmail" className="col-sm-2 col-form-label">Email</label>
                <div className="col-sm-10">
                  <input type="email" className="form-control" id="txtEmail" required
                    onChange={event => setUser({ ...userData, email: event.target.value })} value={userData.email}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="txtAge" className="col-sm-2 col-form-label">Status</label>
                <div className="col-sm-10">
                  <select className="form-select" aria-label="Default select example"
                  onChange={event => setUser({ ...userData, status: event.target.value })} value={userData.status}
                  >
                    <option></option>
                    <option>Enable</option>
                    <option>Disable</option>
                  </select>

                  {/* <input type="text" className="form-control" id="txtPassword" required
                    onChange={event => setUser({ ...userData, password: event.target.value })} value={userData.password}
                  /> */}
                </div>
              </div>

              {/* <div className="mb-3 row">
                <label htmlFor="txtAge" className="col-sm-2 col-form-label">Password</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" id="txtPassword" required
                    onChange={event => setUser({ ...userData, password: event.target.value })} value={userData.password}
                  />
                </div>
              </div>*/}
              <div className="mb-3 row">
                <label htmlFor="txtDOB" className="col-sm-2 col-form-label">Role</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" id="txtRole" required
                    onChange={event => setUser({ ...userData, role: event.target.value })} value={userData.role}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label htmlFor="formFile" className="col-sm-2 col-form-label">Image</label>
                <div className="col-sm-10">
                  <input className="form-control" type="file" id="formFile"
                    onChange={event => { setProfileImage(event) }} />

                  {userData.photo ?
                    <img src={process.env.REACT_APP_BACKEND_URL + "uploads/" + userData.photo}
                      weight="50" height="50" alt={userData.photo} />
                    : ''}

                  {/* {getUserImage.fileName ?
                    <div>
                      test
                      <img src={process.env.REACT_APP_BACKEND_URL + "uploads/" + getUserImage.fileName} weight="50" height="50" alt={getUserImage.filename} />
                    </div>
                    : ''} */}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => updateChanges(userData)}>Save changes</Button>
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>



    </div>
  )
}
