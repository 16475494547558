import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

export const SignUpVerification = () => {
    const { id } = useParams();
    const [getMessage, setMessage] = useState('');
    const baseURL = process.env.REACT_APP_BACKEND_URL

    useEffect(() => {
        verifyTokenForSignup(id)
    }, [])

    const verifyTokenForSignup = async (id) => {
        await axios.get(baseURL + 'employee/verify?token=' + id)
            .then(response => {
                console.log(response.data.message);
                setMessage(response.data.message);
            })
            .catch(error => {
                setMessage(error.response.data.message);
                console.log(error.response.data.message);
            })

    }

    return (
        <div className='d-flex ' style={{ alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
            <div className='text-center'>
                <h4>{getMessage}</h4>
                <p>Click here to <a href='#/login'>Login</a></p>
            </div>
        </div>
    )
}
