import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <ul className="nav d-flex justify-content-center">
        <li className="nav-item"><Link className="nav-link px-2 text-muted" to="/about-us" >  About Us </Link></li>
        <li className="nav-item"><Link className="nav-link px-2 text-muted" to="/feedback">Feedback</Link></li>
        <li className="nav-item"><Link className="nav-link px-2 text-muted" to="/privacy-policy">Privacy Policy</Link></li>
      </ul>
    </footer>
  )
}

export default Footer;
