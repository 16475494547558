
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';


const About = () => {
  useEffect(() => {
    let pageName = 'About Us';
    ReactGA.pageview(pageName);
    document.title = pageName + ' | ' + process.env.REACT_APP_Name;
  }, []);

  return (
    <div className='container-fluid my-3 '>
      <div className='card  p-3'>
        <h2 className='h3'>About Our Quotes APP</h2>
        <p>
          Welcome to quotes.ansarimohammad.com, a community of inspiration, creativity, and motivation! We're more than just a social media app; we're a platform that empowers you to share your thoughts, create meaningful connections, and stay motivated in every aspect of your life.
        </p>
        <p>
          <h4> Our Mission</h4>
          At Motivational Quotes APP, our mission is to provide a positive and uplifting space for people from all walks of life. We're here to inspire and be inspired, to connect and be connected, and to create a world filled with positivity and motivation.
        </p>
        <p>
          <h4>Why Join Our Motivational Quotes APP?</h4>

          <b>Daily Dose of Inspiration</b>: We believe that inspiration can come from anywhere. Join us to discover quotes, stories, and ideas that can brighten your day and ignite your creativity.<br />

          <b>Share Your Wisdom</b>: Your words have the power to inspire others. Share your thoughts, experiences, and unique perspective with a community that appreciates what you have to say.<br />

          <b>Create and Share</b>: Use our tools to craft your own quotes and images. Turn your thoughts into beautiful visuals and share them with the world.<br />

          <b>Connect and Motivate</b>: Interact with like-minded individuals who share your passion for self-improvement, motivation, and positivity. Encourage each other and be part of something bigger.<br />

          <b>Stay Positive</b>: Our Motivational Quotes APP is a place to focus on the bright side of life. Amid the daily challenges, we're here to remind you of the positivity and strength that reside within you.
        </p>

        <p>
          <h4>Our Commitment</h4>

          We are committed to maintaining a supportive and respectful community.
          Your privacy and data security are our top priorities.
          We believe in the power of diversity and welcome users from all backgrounds.
          Our platform is designed to be user-friendly and easy to navigate.
        </p>

        <p>
          <h4>Join Us Today</h4>
          Don't miss out on the opportunity to be a part of our growing community. Join Our Motivational Quotes APP today, and let's inspire, create, connect, and motivate each other. Together, we can make a positive impact on the world.
        </p>



        <p>
          <b>Stay Inspired, Stay Motivated, Stay Positive - Only at quotes.ansarimohammad.com!</b>
        </p>
      </div>
    </div>
  )
}

export default About;