import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";
import { Toast, ToastContainer } from 'react-bootstrap'
import ReactGA from 'react-ga';

const Login = () => {
  useEffect(() => {
    let pageName = 'Login';
    ReactGA.pageview(pageName);
    document.title = pageName + ' | ' + process.env.REACT_APP_Name;
  }, []);
  const [activeTab, setActiveTab] = useState('login');
  const [captchaValueLogin, setCaptchaValueLogin] = useState(null);
  const [captchaValueSignup, setCaptchaValueSignup] = useState(null);
  const [captchaValueForgetPassword, setCaptchaValueForgetPassword] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailSignUp, setEmailSignUp] = useState("");
  const [passwordSignUp, setPasswordSignUp] = useState("");
  const [emailForgetPassword, setEmailForgetPassword] = useState("");

  const getDataSign = async (email, password) => {
    if (!captchaValueLogin) {
      alert("Please complete the CAPTCHA.");
      return;
    }
    let apiData = {
      email,
      password,
    };

    const baseURL = process.env.REACT_APP_BACKEND_URL + "employee/login";

    await axios.post(baseURL, apiData).then((response) => {
      let date = new Date();
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
      let userData = {
        jwt: response.data.token,
        id: response.data.id,
        name: response.data.name,
        email: response.data.email,
        image: response.data.image,
        role: response.data.role,
      };
      document.cookie =
        "user=" + JSON.stringify(userData) + "; expires=" + date + "; path=/";
      // Cookies.set('user', JSON.stringify(userData), { expires: date, secure: true, sameSite: 'strict' });
    });

    window.location = "/";
  };

  const getDataSignUp = async () => {
    if (!captchaValueSignup) {
      handleShowToast({ cat: 'Signup', content: 'Please complete the CAPTCHA.' })
      return;
    }
    if ((emailSignUp == "") | (passwordSignUp == "")) {
      handleShowToast({ cat: 'Signup', content: 'Email and password required' })
    } else {
      let apiData = {
        email: emailSignUp,
        password: passwordSignUp
      };

      const baseURL = process.env.REACT_APP_BACKEND_URL + "employee/signup";

      await axios.post(baseURL, apiData).then((response) => {
        console.log(response);
        handleShowToast({ cat: 'Signup', content: response.data.message })
        // let date = new Date();
        // date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        // let userData = {
        //   jwt: response.data.token,
        //   name: response.data.name,
        //   role: response.data.role,
        // };
        // Cookies.set("user", JSON.stringify(userData), {
        //   expires: date,
        //   secure: true,
        //   sameSite: "strict",
        // });
      })
        .catch(error => {
          handleShowToast({ cat: 'Signup', content: error.data.message })
        });
    }


  };

  const getDataForgetPassword = async () => {

    if (!captchaValueForgetPassword) {
      handleShowToast({ cat: 'Forget Password', content: 'Please complete the CAPTCHA.' })
      return;
    }
    if ((emailForgetPassword == "")) {
      handleShowToast({ cat: 'Forget Password', content: 'Email is required' })
    } else {
      let apiData = {
        email: emailForgetPassword
      };

      const baseURL = process.env.REACT_APP_BACKEND_URL + "employee/forget-password";

      await axios.post(baseURL, apiData)
        .then((response) => {
          console.log(response);
          handleShowToast({ cat: 'Forget Password', content: response.data.message })
        })
        .catch(error => {
          console.log(error);
          handleShowToast({ cat: 'Forget Password', content: error.response.data.message })
        });
    }

  }
  const handleSignIn = (event) => {
    event.preventDefault();
    getDataSign(email, password);
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    getDataSignUp();
  };

  const handleForgetPassword = (event) => {
    event.preventDefault();
    getDataForgetPassword();
  };


  const handleTabSelect = (tab) => {
    setActiveTab(tab);

    let pageName = '';
    if (tab == 'login') {
      pageName = 'Login'
    }
    else if (tab == 'signup') {
      pageName = 'Signup'
    }
    else {
      pageName = 'Forget Password'
    }

    ReactGA.pageview(pageName);
    document.title = pageName + ' | ' + process.env.REACT_APP_Name;
  };


  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  const ToastMessage = ({ show, onClose, message }) => {
    return (
      <Toast show={show} onClose={onClose}>
        <Toast.Header>
          <strong className='me-auto'>{message.cat}</strong>
        </Toast.Header>
        <Toast.Body>{message.content}</Toast.Body>
      </Toast>
    )
  }

  const handleShowToast = message => {
    setToastMessage(message)
    setShowToast(true)

    // Hide the toast after a certain duration
    setTimeout(() => {
      setShowToast(false)
    }, 5000) // 3 seconds
  }


  return (
    <div>
      <ToastContainer
        style={{ top: '20px', right: '20px', position: 'fixed' }}
        className='p-3'
      >
        <ToastMessage
          show={showToast}
          onClose={() => setShowToast(false)}
          message={toastMessage}
        />

      </ToastContainer>
      <div className="container-fluid login-bg">
        <div className="d-flex justify-content-center align-items-center " style={{ height: 'calc(100vh - 100px)' }}>
          <div className="bg-dark p-2 text-dark bg-opacity-25 rounded p-4 d-block">

            {activeTab === 'login' && (
              <Form onSubmit={handleSignIn} >

                {/* <div className="text-center text-white h3 ">
                  <img src="./logo512.png" style={{ height: '100px' }} />
                  <br />Fuel Your Inspiration, Sign In
                </div> */}
                <Form.Group>

                  <Form.Control
                    className="mt-3"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  {/* <Form.Label htmlFor="password">Password</Form.Label> */}
                  <Form.Control
                    className="mt-3"
                    autoComplete="off"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <ReCAPTCHA className="my-3"
                  sitekey="6LePiWEoAAAAAD_hFVsbKYyeWPhPj7ArAtrmN5yg"
                  onChange={(value) => setCaptchaValueLogin(value)}
                />

                <Button type="submit" className="w-100 mb-3 ">Login</Button>
                <div className="d-flex">
                  <Button className="w-50 me-2 btn-secondary" onClick={() => handleTabSelect('signup')}>Signup</Button>
                  <Button className="w-50 btn-secondary" onClick={() => handleTabSelect('forget-password')}>Forgot Password</Button>
                </div>
              </Form>
            )}

            {activeTab === 'signup' && (
              <Form onSubmit={handleSignUp} >

                {/* <div className="text-center text-white h3 ">
                  <img src="./logo512.png" style={{ height: '100px' }} />
                  <br />Unlock Endless Inspiration
                </div> */}
                <Form.Group>
                  {/* <Form.Label htmlFor="email">Email</Form.Label> */}
                  <Form.Control
                    className="mt-3"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    value={emailSignUp}
                    onChange={(e) => setEmailSignUp(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  {/* <Form.Label htmlFor="password">Password</Form.Label> */}
                  <Form.Control
                    className="mt-3"
                    autoComplete="off"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter your password"
                    value={passwordSignUp}
                    onChange={(e) => setPasswordSignUp(e.target.value)}
                  />
                </Form.Group>
                <ReCAPTCHA className="my-3"
                  sitekey="6LePiWEoAAAAAD_hFVsbKYyeWPhPj7ArAtrmN5yg"
                  onChange={(value) => setCaptchaValueSignup(value)}
                />
                <Button type="submit" className="w-100 mb-3 ">Signup</Button>
                <div className="d-flex">
                  <Button className="w-50 me-2 btn-secondary" onClick={() => handleTabSelect('login')}>Login</Button>
                  <Button className="w-50 btn-secondary" onClick={() => handleTabSelect('forget-password')}>Forgot Password</Button>
                </div>
              </Form>
            )}

            {activeTab === 'forget-password' && (
              <Form onSubmit={handleForgetPassword} >
                {/* <div className="text-center text-white h3 ">
                  <img src="./logo512.png" style={{ height: '100px' }} />
                  <br />Recover Your Access
                </div> */}
                <Form.Group>
                  {/* <Form.Label htmlFor="email">Email</Form.Label> */}
                  <Form.Control
                    className="mt-3"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    value={emailForgetPassword}
                    onChange={(e) => setEmailForgetPassword(e.target.value)}
                  />
                </Form.Group>
                <ReCAPTCHA className="my-3"
                  sitekey="6LePiWEoAAAAAD_hFVsbKYyeWPhPj7ArAtrmN5yg"
                  onChange={(value) => setCaptchaValueForgetPassword(value)}
                />
                <Button type="submit" className="w-100 mb-3 ">Forgot Password</Button>
                <div className="d-flex">
                  <Button className="w-50 me-2 btn-secondary" onClick={() => handleTabSelect('login')}>Login</Button>
                  <Button className="w-50 btn-secondary" onClick={() => handleTabSelect('signup')}>Signup</Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
