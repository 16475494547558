import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState, useRef } from 'react'
import { GetToken } from '../utils/GetToken'
import { CommonQuoteList } from '../Components/CommonQuoteList'
import { useParams } from 'react-router-dom'
import { Toast, ToastContainer } from 'react-bootstrap'

const QuoteByUser = () => {
  const baseURL = process.env.REACT_APP_BACKEND_URL
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const pageRef = useRef(1);
  const [getQuoteList, setQuoteList] = useState([])
  let page = 0;
  const [getUser, setUser] = useState([])
  let startNextDownload = true;
  const userTokenData = JSON.parse(
    Cookies.get("user") ? Cookies.get("user") : "{}"
  );
  const userId = Cookies.get("user") ? userTokenData.id : "";

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  const ToastMessage = ({ show, onClose, message }) => {
    return (
      <Toast show={show} onClose={onClose}>
        <Toast.Header>
          <strong className='me-auto'>{message.cat}</strong>
        </Toast.Header>
        <Toast.Body>{message.content}</Toast.Body>
      </Toast>
    )
  }

  const handleShowToast = message => {
    setToastMessage(message)
    setShowToast(true)

    // Hide the toast after a certain duration
    setTimeout(() => {
      setShowToast(false)
    }, 5000) // 3 seconds
  }

  const handleObserver = (entities) => {
    const target = entities[0];
    if (startNextDownload) {
      if (target.isIntersecting) {
        page++
        getData(); // Load more data when the target element is in view
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null, // Use the viewport as the root
      rootMargin: '10px', // No margin
      threshold: 1.0, // Fully visible
    });

    const target = document.querySelector('.scroll-trigger'); // Element at the bottom of the content
    if (target) {
      observer.observe(target);
    }
    return () => observer.disconnect(); // Cleanup when unmounting
  }, []);

  useEffect(() => {
    getUserDetails(id);

  }, [])

  const getUserDetails = async (userId) => {
    await axios
      .get(baseURL + 'Employee/public/' + userId)
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        console.log(error.response.data.message)
      })
  }

  const getData = async () => {
    setLoading(true);
    if (Cookies.get('user') != undefined) GetToken()
    await axios
      .get(baseURL + 'quote/user?userId=' + id + '&page=' + page)
      .then(response => {
        // const sortedData = response.data.sort(
        //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        // )
        // const filtered = sortedData.filter(item => item.userId === id)
        // setQuoteList(filtered)
        setQuoteList((getQuoteList) => [...getQuoteList, ...response.data]);

        if (response.data.length < 10) {
          startNextDownload = false;
        }
      })
      .catch(error => {
        console.log(error.data.message)
      })
    setLoading(false);
    pageRef.current++;
  }


  const userFollow = async (id, quote) => {

    if (userSessionExist()) {

      let apiData = {
        FollowedTo: id
      }
      GetToken()
      await axios.post(baseURL + 'Employee/Follow', apiData).then(response => {
        // props.getData();
        getUserDetails(id);
      })
      let message = { cat: 'Follow Status', content: quote }
      handleShowToast(message)
    } else {
      let message = { cat: 'Error Following', content: 'user need to login.' }
      handleShowToast(message)
    }
  }

  const userSessionExist = () => {
    return Cookies.get('user') !== undefined
  }


  return (
    <div className='container-fluid'>
      <ToastContainer
        style={{ top: '20px', right: '20px', position: 'fixed' }}
        className='p-3'
      >
        <ToastMessage
          show={showToast}
          onClose={() => setShowToast(false)}
          message={toastMessage}
        />
      </ToastContainer>
      <div className='row py-3'>
        <div className='col-lg-2'>
          <div className="card mb-4">
            <div className="card-body text-center">
              <div className='square-container'>
                <img
                  src={baseURL + 'uploads/' + (getUser.user?.photo == undefined ? '230201142911647.webp' : getUser.user?.photo)} alt="avatar"
                  className='square-image'
                />
              </div>
              <h5 className="my-3">{getUser.user?.name}</h5>
              <p className="text-muted mb-1">Quotes : {getUser.QuoteCount}</p>
              <p className="text-muted mb-1">Follower : {getUser.FollowerCount}</p>
              <p className="text-muted mb-4">Following : {getUser.FollowingCount}</p>
              <div className="d-flex justify-content-center mb-2">

                {/* if session id and user id is not same */}

                {userId == id ? '' :

                  <button type="button" className="btn btn-primary"
                    onClick={() => userFollow(id, (getUser.isFollow == 0 ? 'Follow' : 'Unfollow') + " - " + getUser.user?.name)}
                  >{getUser.isFollow == 0 ? 'Follow' : 'Unfollow'}</button>
                }
                {/* <button type="button" className="btn btn-outline-primary ms-1">Message</button> */}
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-10'>
          <CommonQuoteList getQuoteList={getQuoteList} />
          {loading && <div className='loader' title="Please Waiting"></div>}
          <div className="scroll-trigger"></div>
        </div>
      </div>
    </div>
  )
}

export default QuoteByUser
