import './App.css';
import { Routes, Route } from "react-router-dom";
import { HashRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import Primary from './Layouts/Primary';
import About from './Pages/About';
import Login from './Pages/Login';
import Home from './Pages/Home';
import NoPage from './Pages/NoPage';
import { UserList } from './Pages/UserList';
import { QuoteList } from './Pages/QuoteList';
import { QuoteByMe } from './Pages/QuoteByMe';
import { Category } from './Pages/Admin/Category';
import { Author } from './Pages/Admin/Author';
import Feedback from './Pages/Feedback';
import { FeedbackList } from './Pages/Admin/FeedbackList';
import Favorite from './Pages/Favorite';
import QuoteByUser from './Pages/QuoteByUser';
import QuoteByCategory from './Pages/QuoteByCategory';
import QuoteByAuthor from './Pages/QuoteByAuthor';
import { Followers } from './Pages/Followers';
import { Following } from './Pages/Following';
import { Profile } from './Pages/Profile';
import { SignUpVerification } from './Pages/SignUpVerification';
import { ForgetPasswordVerification } from './Pages/ForgetPasswordVerification';
import { QuoteWallpaper } from './Pages/QuoteWallpaper';
import { PrivacyPolicy } from './Pages/PrivacyPolicy';

ReactGA.initialize('G-F0C45K6N9V');
function App() {

  return (

    <>
      <HashRouter>
        <Routes>
          <Route path="/">
            <Route index element={
              <Primary >
                <Home />
              </Primary>
            } />
            <Route path="login" element={
              <Primary>
                <Login />
              </Primary>
            } />
            <Route path="/favorite" element={
              <Primary>
                <Favorite />
              </Primary>
            } />
            <Route path="about-us" element={
              <Primary>
                <About />
              </Primary>
            } />
            <Route path="privacy-policy" element={
              <Primary>
                <PrivacyPolicy />
              </Primary>
            } />
            <Route path="user" element={
              <Primary>
                <UserList />
              </Primary>
            } />
            <Route path="/user/:id" element={
              <Primary>
                <QuoteByUser />
              </Primary>
            } />
            <Route path="/category/:id" element={
              <Primary>
                <QuoteByCategory />
              </Primary>
            } />

            <Route path="/author/:id" element={
              <Primary>
                <QuoteByAuthor />
              </Primary>
            } />
            <Route path="/admin/category" element={
              <Primary>
                <Category />
              </Primary>
            } />
            <Route path="/admin/author" element={
              <Primary>
                <Author />
              </Primary>
            } />
            <Route path="my-quotes" element={
              <Primary>
                <QuoteByMe />
              </Primary>
            } />

            <Route path="quotes" element={
              <Primary>
                <QuoteList />
              </Primary>
            } />

            <Route path="wallpaper" element={
              <Primary>
                <QuoteWallpaper />
              </Primary>
            } />
            <Route path="wallpaper/category/:categoryName" element={
              <Primary>
                <QuoteWallpaper />
              </Primary>
            } />
            <Route path="wallpaper/author/:authorName" element={
              <Primary>
                <QuoteWallpaper />
              </Primary>
            } />
            <Route path="wallpaper/user/:user" element={
              <Primary>
                <QuoteWallpaper />
              </Primary>
            } />
            <Route path="wallpaper/quoteId/:quoteId" element={
              <Primary>
                <QuoteWallpaper />
              </Primary>
            } />
            <Route path="contact" element={
              <Primary>
                Contact Us
              </Primary>
            } />
            <Route path="feedback" element={
              <Primary>
                <Feedback />
              </Primary>
            } />
            <Route path="Followers" element={
              <Primary>
                <Followers />
              </Primary>
            } />
            <Route path="Following" element={
              <Primary>
                <Following />
              </Primary>
            } />
            <Route path="profile" element={
              <Primary>
                <Profile />
              </Primary>
            } />
            <Route path="admin/feedback-list" element={
              <Primary>
                <FeedbackList />
              </Primary>
            } />
            <Route path="verify-change-password/:id" element={
              <Primary>
                <SignUpVerification />
              </Primary>
            } />

            <Route path="verify-forget-password/:id" element={
              <Primary>
                <ForgetPasswordVerification />
              </Primary>
            } />
            <Route path="*" element={
              <Primary>
                <NoPage />
              </Primary>
            } />
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
