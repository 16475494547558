import React, { useState, useEffect } from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';




const Primary = (props) => {

  
const [canInstall, setCanInstall] = useState(false);
const [deferredPrompt, setDeferredPrompt] = useState(null);
const [isPwaContainerVisible, setPwaContainerVisible] = useState(true);


const handleInstallClick = () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      setDeferredPrompt(null);
      setPwaContainerVisible(false); // Hide the container
    });
  }
};

const handleNotNowClick = () => {
  // Handle the "Not Now" button click event
  console.log('User clicked "Not Now"');
  setPwaContainerVisible(false);
  // You can add any other desired actions here
};

useEffect(() => {
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the default browser install prompt
    e.preventDefault();
    // Stash the event for later use
    setDeferredPrompt(e);
    setCanInstall(true);
  });

  return () => {
    // Clean up the event listener when the component unmounts
    window.removeEventListener('beforeinstallprompt');
  };
}, []);


  return (
    <div>
      {canInstall ?
        isPwaContainerVisible && (
          <div>
            <div className='pwa-container'>
              <div className="card">
                <div className="card-body text-center">
                  <h6 className="card-title">Welcome to our Web App</h6>
                  <p className="card-text"><small>Install our app which is free, fast, takes less space.</small></p>
                  <button className="btn btn-primary btn-sm me-2" onClick={handleInstallClick}>Install App</button>
                  <button className="btn btn-secondary btn-sm" onClick={handleNotNowClick}>Not Now</button>
                </div>
              </div>
            </div>
          </div>
        )
        : ''}

      <Header />
      <main className='mb-5'>
        {props.children}
      </main>
      <Footer />
    </div>

  )
}
export default Primary;