import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState, useRef } from 'react'
import { GetToken } from '../utils/GetToken'
import { CommonQuoteList } from '../Components/CommonQuoteList'
import ReactGA from 'react-ga';

const Home = () => {
  const baseURL = process.env.REACT_APP_BACKEND_URL

  const [loading, setLoading] = useState(false);
  const pageRef = useRef(1);
  const [getQuoteList, setQuoteList] = useState([])
  let page = 0;
  let startNextDownload = true;

  const handleObserver = (entities) => {
    const target = entities[0];

    if (target.isIntersecting) {
      if (startNextDownload) {
        page++
        getData(); // Load more data when the target element is in view
      }
    }
  };

  useEffect(() => {
    let pageName = 'Home';
    ReactGA.pageview(pageName);
    document.title = pageName + ' | ' + process.env.REACT_APP_Name;
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null, // Use the viewport as the root
      rootMargin: '10px', // No margin
      threshold: 1.0, // Fully visible
    });

    const target = document.querySelector('.scroll-trigger'); // Element at the bottom of the content
    if (target) {
      observer.observe(target);
    }
    return () => observer.disconnect(); // Cleanup when unmounting
  }, []);

  const getData = async () => {
    setLoading(true);
    if (Cookies.get('user') != undefined) GetToken()
    await axios
      .get(baseURL + 'quote/public?page=' + page)
      .then(response => {
        if (response.data.length < 10) {
          startNextDownload = false;
        }
        setQuoteList((getQuoteList) => [...getQuoteList, ...response.data]);
      })
      .catch(error => {
        console.log(error.response.data.message)
      })
    setLoading(false);
    pageRef.current++;
  }


  return (
    <div className='container-fluid py-3' >
      <CommonQuoteList getQuoteList={getQuoteList} />
      {loading && <div className='loader' ></div>}
      <div className="scroll-trigger"></div>
    </div>
  )
}

export default Home
