import React from 'react'
import { useEffect } from 'react';
import ReactGA from 'react-ga';

export const PrivacyPolicy = () => {
    useEffect(() => {
        let pageName = 'privacy-policy';
        ReactGA.pageview(pageName);
        document.title = pageName + ' | ' + process.env.REACT_APP_Name;
    }, []);
    return (
        <div className='container-fluid my-3 '>
            <div className='card  p-3'>
                <h2 className='h3'>Privacy Policy</h2>

                <p>
                    Welcome to quotes.ansarimohammad.com. At Our Quotes Application, we value and respect your privacy. This Privacy Policy is designed to help you understand how we collect, use, and safeguard your personal information. By using our application, you agree to the practices described in this policy.
                </p>

                <h4>Information We Collect</h4>

                <p>
                    We may collect the following types of information:<br />

                    <b>Personal Information</b>: When you create an account, we may collect your name, email address, and other information you provide to us.<br />

                    <b>User-Generated Content</b>: This includes quotes, comments, and any other content you create or post on our application.<br />

                    <b>Usage Information</b>: We collect information about how you use our application, such as the features you use and the content you interact with.
                </p>

                <h4>How We Use Your Information</h4>

                <p>
                    We use the information we collect for the following purposes:<br />

                    <b>User Account</b>: To create and manage your account.<br />

                    <b>Content Sharing</b>: To allow you to create and share quotes, as well as interact with other users through likes and comments.<br />

                    <b>Personalization</b>: To personalize your experience on our application, such as recommending content and wallpapers.<br />

                    <b>Communication</b>: To communicate with you about our services, updates, and important notifications.
                </p>

                <h4>Sharing Your Information</h4>
                <p>
                    We may share your information in the following situations:<br />

                    <b>With Other Users</b>: Your user-generated content, such as quotes and comments, may be visible to other users of the application.<br />

                    <b>Third-Party Services</b>: We may use third-party services to help operate our application, and they may have access to your information for this purpose.<br />

                    <b>Legal Requirements</b>: We may disclose your information if required by law or in response to a legal request.
                </p>

                <p> We take the security of your information seriously and have implemented reasonable security measures to protect your data. However, please be aware that no method of transmission over the internet or electronic storage is completely secure.</p>

                <h4>Changes to This Policy</h4>

                <p>We may update this Privacy Policy from time to time to reflect changes in our practices. We encourage you to review this policy periodically.</p>

                <h4>Contact Us</h4>

                <p>If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information, please contact us at quotes@ansarimohammad.com.</p>
            </div>
        </div>
    )
}
