import React, { useEffect, useState } from 'react'
import axios from 'axios'
// import Cookies from 'js-cookie';
import { GetToken } from '../../utils/GetToken'

const AddQuotePopup = props => {
  const [status, setStatus] = useState(true)
  const [getAuthor, setAuthor] = useState('')
  const [getCategory, setCategory] = useState('')
  const [getQuote, setQuote] = useState('')
  const [getVisibility, setVisibility] = useState('')
  const [getCategoryList, setCategoryList] = useState([])
  const [getAuthorList, setAuthorList] = useState([])

  const baseURL = process.env.REACT_APP_BACKEND_URL
  GetToken()

  const getCategories = async () => {
    await axios.get(baseURL + 'category').then(res => {
      setCategoryList(res.data)
    })
  }

  const getAuthors = async () => {
    await axios.get(baseURL + 'author').then(res => {
      setAuthorList(res.data)
    })
  }

  useEffect(() => {
    getCategories()
    getAuthors()
  }, [])

  const handleSubmit = event => {
    event.preventDefault()
    const Quote = {
      author: getAuthor,
      quote: getQuote,
      category: getCategory,
      status: getVisibility
    }

    if (getAuthor == '' | getQuote == '' | getCategory == '' | getVisibility == '') {
      console.log('form cannot submit')
    } else {
      // console.log('form can submit')
      axios.post(baseURL + 'quote', Quote).then(res => {
        props.onInsert()
        setAuthor('')
        setCategory('')
        setQuote('')
        setVisibility('')
        setStatus(false)
      })
    }
  }

  return (
    <div>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#exampleModal'
      >
        Add New Quote
      </button>

      <div
        className='modal fade'
        id='exampleModal'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            {status ? (
              <form onSubmit={handleSubmit}>
                <div className='modal-header'>
                  <h1 className='modal-title fs-5' id='exampleModalLabel'>
                    New Quote
                  </h1>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                  ></button>
                </div>

                <div className='modal-body'>
                  <div className='mb-3 row'>
                    <label
                      htmlFor='txtName'
                      className='col-sm-2 col-form-label'
                    >
                      Author
                    </label>
                    <div className='col-sm-10'>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        onChange={event => setAuthor(event.target.value)}
                        value={getAuthor}
                      >
                        <option></option>
                        {getAuthorList?.map((author, index) => {
                          return (
                            <option key={index} value={author._id}>
                              {author.name}
                            </option>
                          )
                        })}
                      </select>
                      {/* <input type="text" className="form-control" id="txtName" required
                                                    onChange={event => setAuthor(event.target.value)} value={getAuthor} /> */}
                    </div>
                  </div>
                  <div className='mb-3 row'>
                    <label htmlFor='txtAge' className='col-sm-2 col-form-label'>
                      Category
                    </label>
                    <div className='col-sm-10'>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        onChange={event => setCategory(event.target.value)}
                        value={getCategory}
                      >
                        <option></option>
                        {getCategoryList?.map((category, index) => {
                          return (
                            <option key={index} value={category._id}>
                              {category.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>

                  <div className='mb-3 row'>
                    <label
                      htmlFor='txtAddress'
                      className='col-sm-2 col-form-label'
                    >
                      Quote
                    </label>
                    <div className='col-sm-10'>
                      <textarea
                        className='form-control'
                        id='txtAddress'
                        required
                        rows='3'
                        onChange={event => setQuote(event.target.value)}
                        value={getQuote}
                      ></textarea>
                    </div>
                  </div>

                  <div className='mb-3 row'>
                    <label
                      htmlFor='txtEmail'
                      className='col-sm-2 col-form-label'
                    >
                      Status
                    </label>
                    <div className='col-sm-10'>
                      <select
                        className='form-select'
                        aria-label='Default select example'
                        onChange={event => setVisibility(event.target.value)}
                        value={getVisibility}
                      >
                        <option></option>
                        <option>Public</option>
                        <option>Private</option>
                      </select>
                      {/* <input type="text" className="form-control" id="txtEmail" required
                                                    onChange={event => setVisibility(event.target.value)} value={getVisibility} /> */}
                    </div>
                  </div>
                </div>

                <div className='modal-footer'>
                  <button type='submit' className='btn btn-primary'>
                    Save changes
                  </button>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    data-bs-dismiss='modal'
                  >
                    Close
                  </button>
                </div>
              </form>
            ) : (
              <button
                type='button'
                className='btn btn-default'
                data-bs-dismiss='modal'
                onClick={() => {
                  setTimeout(() => {
                    setStatus(true)
                  }, 500)
                }}
              >
                Quote Added successfully, click me to close.
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddQuotePopup
