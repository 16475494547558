import React from 'react'

const NoPage = () => {

  //want write a code to consume api

  return (
    <div>
      No Page Found.
      
    </div>
  )
}

export default NoPage
