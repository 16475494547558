import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import moment from 'moment/moment';

export const Profile = () => {
    const baseURL = process.env.REACT_APP_BACKEND_URL + "employee";


    const userTokenData = JSON.parse(
        Cookies.get("user") ? Cookies.get("user") : "{}"
    );
    const email = Cookies.get("user") ? userTokenData.email : "";
    const name = Cookies.get("user") ? userTokenData.name : "";
    const image = Cookies.get("user") ? userTokenData.image : "";

    const [getFormUpdateProfile, setFormUpdateProfile] = useState(true);
    const [getName, setName] = useState(name);
    const [getEmail, setEmail] = useState(email);
    const [getPhoto, setPhoto] = useState(image);
    const [getOldPassword, setOldPassword] = useState('');
    const [getNewPassword, setNewPassword] = useState('');
    const [getConfirmPassword, setConfirmPassword] = useState('');

    const setProfileImage = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("fileUpload", file);
        let fileName = moment().format("YYMMDDHHmmssSSS");
        formData.append("img_name", fileName);

        await axios.post(baseURL + "/image", formData)
            .then((response) => {
                console.log(response.data, 12);
                setPhoto(response.data.filename)
                // setUser({
                //     ...userData,
                //     photo: response.data.filename
                // })
                // setUserImage(response.data.filename)
            })
            .catch((error) => {
                console.log(error, 13);
            });
    }


    const uploadFileToServer = async (file) => {


    }


    const UpdateProfile = async (event) => {
        event.preventDefault()
        const data = {
            email: getEmail,
            name: getName,
            photo: getPhoto,
        }
        console.log(data);
        await axios.put(baseURL + "/profile", data)
            .then(res => {
                // getData();
                // handleClose();
            })
        // const file = event.target.files[0];
        // const reader = new FileReader();
        // reader.onload = function () {
        //     // setPhoto(reader.result);
        //     setPhoto(test);
        // }
        // reader.readAsDataURL(file);
    }

    const UpdatePassword = async (event) => {
        event.preventDefault()
        if (getNewPassword == getConfirmPassword) {
            const formData = {
                oldPassword: getOldPassword,
                newPassword: getNewPassword,
            }


            await axios.post(baseURL + "/change-password", formData)
                .then((response) => {
                    console.log("Password changed. :)");
                    setOldPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                })
                .catch((error) => {
                    console.log(error, 13);
                });
            // console.log(formData);
        }
        else {
            console.log("password mismatch");
        }
    }

    return (
        <div className='container-fluid '>
            <div className='bg-white p-3 mt-3 rounded'>

                {getFormUpdateProfile ?
                    <form onSubmit={UpdateProfile}>
                        <h5>Update My Profile</h5>
                        <div className="my-3 row">
                            <label htmlFor="txtEmail" className="col-sm-2 col-form-label">Email</label>
                            <div className="col-sm-4">
                                <input type="email" autoComplete='off' className="form-control" id="txtEmail" disabled required
                                    onChange={event => setEmail(event.target.value)} value={getEmail} readOnly />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="txtName" className="col-sm-2 col-form-label">Name</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="txtName" required
                                    onChange={event => setName(event.target.value)} value={getName} />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label htmlFor="formFile" className="col-sm-2 col-form-label">Profile Image</label>
                            <div className="col-sm-4">
                                {/* <input className="form-control" type="file" id="formFile"
                                    onChange={event => { setPhoto(event.target.files[0]) }} /> */}
                                <input className="form-control" type="file" id="formFile"
                                    onChange={event => { setProfileImage(event) }} />
                            </div>
                        </div>

                        <div>
                            <button type='submit' className='btn btn-primary me-2'>Update Profile</button>
                            <button className='btn btn-secondary' onClick={() => { setFormUpdateProfile(false) }}>Change Password</button>
                        </div>
                    </form>
                    :
                    <form onSubmit={UpdatePassword}>
                        <h5>Change My Password</h5>
                        <div className="my-3 row">
                            <label htmlFor="txtOldPassword" className="col-sm-2 col-form-label">Old Password</label>
                            <div className="col-sm-4">
                                <input type="text" autoComplete='off' className="form-control" id="txtOldPassword" required
                                    onChange={event => setOldPassword(event.target.value)} value={getOldPassword} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="txtNewPassword" className="col-sm-2 col-form-label">New Password</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="txtNewPassword" required
                                    onChange={event => setNewPassword(event.target.value)} value={getNewPassword} />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label htmlFor="txtConfirmPassword" className="col-sm-2 col-form-label">Confirm Password</label>
                            <div className="col-sm-4">
                                <input type="text" className="form-control" id="txtConfirmPassword" required
                                    onChange={event => setConfirmPassword(event.target.value)} value={getConfirmPassword} />
                            </div>
                        </div>

                        <div>
                            <button type='submit' className='btn btn-primary me-2'>Change Password Now</button>
                            <button className='btn btn-secondary' onClick={() => { setFormUpdateProfile(true) }}>Cancel</button>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}
