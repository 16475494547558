import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState, useRef } from 'react'
import { GetToken } from '../utils/GetToken'
import { CommonQuoteList } from '../Components/CommonQuoteList'
const Favorite = () => {
  const baseURL = process.env.REACT_APP_BACKEND_URL

  const [loading, setLoading] = useState(false);
  const pageRef = useRef(1);
  const [getQuoteList, setQuoteList] = useState([])

  let page = 0;
  let startNextDownload = true;
  const handleObserver = (entities) => {
    const target = entities[0];
    if (startNextDownload) {
      if (target.isIntersecting) {
        page++
        getData(); // Load more data when the target element is in view
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null, // Use the viewport as the root
      rootMargin: '10px', // No margin
      threshold: 1.0, // Fully visible
    });

    const target = document.querySelector('.scroll-trigger'); // Element at the bottom of the content
    if (target) {
      observer.observe(target);
    }
    return () => observer.disconnect(); // Cleanup when unmounting
  }, []);

  const getData = async () => {
    setLoading(true);
    if (Cookies.get('user') != undefined) GetToken()
    await axios
      .get(baseURL + 'quote/favorite?page=' + page)
      .then(response => {
        // const sortedData = response.data.sort(
        //   (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        // )
        // const filtered = sortedData.filter(item => item.userStatus === 'Like');
        // setQuoteList(filtered)
        setQuoteList((getQuoteList) => [...getQuoteList, ...response.data]);

        if (response.data.length < 10) {
          startNextDownload = false;
        }
      })
      .catch(error => {
        console.log(error.response.data.message)
      })
    setLoading(false);
    pageRef.current++;
  }

  return (
    <div className='container-fluid py-3' >
      <CommonQuoteList getQuoteList={getQuoteList} />
      {loading && <div className='loader' title="Please Waiting"></div>}
      <div className="scroll-trigger"></div>
    </div>
  )
}

export default Favorite
