import React, { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { GetToken } from "../utils/GetToken";
import axios from "axios";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faComment,
  faCommentAlt,
  faCopy,
  faEnvelope,
  faExclamationTriangle,
  faHeart,
  faImages,
  faShare,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGetPocket,
  faLine,
  faLinkedin,
  faPinterest,
  faTelegram,
  faTumblr,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { AddQuoteWallpaper } from "./AddQuoteWallpaper";
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  PocketShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useEffect } from "react";

export const CommonQuoteList = (props) => {

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  const baseURL = process.env.REACT_APP_BACKEND_URL;
  const [quoteData, setQuoteData] = useState([])
  // let quoteData = props.getQuoteList;
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [dataComment, setDataComment] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
  const [popupValue, setPopupValue] = useState("");

  const [userComment, setUserComment] = useState([]);
  const [userCommentReport, setUserCommentReport] = useState([]);

  useEffect(() => {
    setQuoteData(props.getQuoteList)
  }, [props.getQuoteList])


  // const ToastMessage = ({ show, onClose, message }) => {
  //   return (
  //     <Toast show={show} onClose={onClose}>
  //       <Toast.Header>
  //         <strong className="me-auto">{message.cat}</strong>
  //       </Toast.Header>
  //       <Toast.Body>{message.content}</Toast.Body>
  //     </Toast>
  //   );
  // };

  const handleShowToast = (message) => {
    setToastMessage(message);
    setShowToast(true);

    // Hide the toast after a certain duration
    setTimeout(() => {
      setShowToast(false);
    }, 5000); // 3 seconds
  };

  const openPopup = (value) => {
    setPopupValue(value);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const quoteLike = async (id, quote) => {
    if (userSessionExist()) {
      // DB Save UserID, QuoteID, TimeStamp -> QuoteLike

      let apiData = {
        quoteId: id,
      };
      GetToken();
      await axios.post(baseURL + "quote/like", apiData).then((response) => {


        const itemToUpdate = quoteData.find(item => item._id === id);
        if (itemToUpdate) {
          if (itemToUpdate.userStatus == "Like") {
            itemToUpdate.totalLike = itemToUpdate.totalLike - 1;
            itemToUpdate.userStatus = "NotLike";
            handleShowToast({ cat: "your like is removed", content: quote });
          }
          else {
            itemToUpdate.userStatus = "Like";
            itemToUpdate.totalLike = itemToUpdate.totalLike + 1;
            handleShowToast({ cat: "you liked this quote", content: quote });
          }
          setQuoteData([...quoteData]);
        }

      });


    } else {
      let message = { cat: "Like Quote", content: "login" };
      handleShowToast(message);
    }
  };


  const sendReport = async (quoteId, quoteContent, comment) => {
    if (!comment) {
      let message = { cat: "Send Report ", content: "Need reason for Reporting." };
      handleShowToast(message);
      return
    }

    if (userSessionExist()) {
      let apiData = {
        quoteId: quoteId,
        comment,
      };

      GetToken();
      await axios.post(baseURL + "quote/Report", apiData).then((response) => {
        let message = { cat: "You report successfully send.", content: quoteContent };
        handleShowToast(message);
        setUserCommentReport('');
      });

    } else {
      let message = { cat: "Send Report ", content: "login" };
      handleShowToast(message);
    }
  }

  // const quoteReport = async (id, quote) => {
  //   if (userSessionExist()) {
  //     // DB Save UserID, QuoteID, TimeStamp -> QuoteLike

  //     let apiData = {
  //       quoteId: id,
  //     };
  //     GetToken();
  //     await axios.post(baseURL + "quote/Report", apiData).then((response) => {
  //     });

  //     let message = { cat: "You reported", content: quote };
  //     handleShowToast(message);
  //   } else {
  //     let message = { cat: "Report About Quote", content: "login" };
  //     handleShowToast(message);
  //   }
  // };

  const quoteCopy = (quote) => {
    const textArea = document.createElement("textarea");
    var url = "https://quotes.ansarimohammad.com";
    textArea.value = url + " " + quote;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    handleShowToast({ cat: "Quote copied successfully", content: quote });
  };

  const userSessionExist = () => {
    return Cookies.get("user") !== undefined;
  };

  const quotePopupOpen = (params) => {
    openPopup(params);
  };

  const formatDate = (str) => {
    let date = new Date(str);
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hour = ("0" + date.getHours()).slice(-2);
    let min = ("0" + date.getMinutes()).slice(-2);
    return [day, month, date.getFullYear()].join("/") + " " + hour + " : " + min;
  }

  const getCommentData = async (quoteId) => {
    setDataComment([]);
    await axios
      .get(baseURL + 'quote/comment?quoteId=' + quoteId)
      .then(response => {
        console.log(response.data);
        setDataComment(response.data);
      })
      .catch(error => {
        console.log(error.response.data.message)
      })
  }

  const sendComment = async (quoteId, quoteContent, comment) => {
    let apiData = {
      quoteId: quoteId,
      comment: comment,
    };

    if (userSessionExist()) {

      GetToken();
      await axios.post(baseURL + "quote/comment", apiData).then((response) => {
        let message = { cat: "You comment successfully send.", content: quoteContent };
        handleShowToast(message);
        setUserComment('');
      });
      // console.log(apiData);
    } else {
      let message = { cat: "Send Comment ", content: "login" };
      handleShowToast(message);
    }
    // console.log("ID : ", quoteId, ", Quote : " + quoteContent + ", Comment : " + comment);
    // setUserComment('');

    // console.log(apiData);
  }

  const handleInputChange = (event, index) => {
    // Step 4: Update the state variable with the new input value
    const updatedInputValues = [...userComment];
    updatedInputValues[index] = event.target.value;
    setUserComment(updatedInputValues);
  };

  const handleInputReportChange = (event, index) => {
    // Step 4: Update the state variable with the new input value
    const updatedInputValues = [...userCommentReport];
    updatedInputValues[index] = event.target.value;
    setUserCommentReport(updatedInputValues);
  };

  const CommentButtons = (quoteId, quoteContent, index, totalComment) => {
    return (
      <div className="share-dropdown">
        <button
          className="dropdown-toggle border-0"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={() => getCommentData(quoteId)}
        >
          <FontAwesomeIcon icon={faCommentAlt} title="Write Comment" className="text-primary" /> {totalComment}
        </button>
        <ul
          className="dropdown-menu p-2"
          style={{ width: "calc(100% - 30px)" }}
        >
          <li className="w-100">
            <input
              name='txtComment'
              placeholder="enter comment here"
              className="no-focus-outline border-0"
              value={userComment[index] || ''}
              onChange={(e) => handleInputChange(e, index)}
              style={{ width: "75%" }}
            />
            <button className="border-0" style={{ width: "25%" }} onClick={() => sendComment(quoteId, quoteContent, userComment[index])}>
              Send
            </button>
            <hr className="my-1" />
          </li>
          <li style={{ maxHeight: '200px', overflow: 'auto', marginTop: '2px' }}>

            {dataComment.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div>
                    <div className="d-flex flex-start">
                      {item.resultUser[0].photo ? (
                        <img
                          src={baseURL + "uploads/" + item.resultUser[0].photo}
                          style={{
                            height: "30px",
                            width: "30px",
                            marginRight: "5px",
                          }}
                          className="img-fluid rounded-circle"
                          alt={item.photo}
                        />
                      ) : (
                        <img
                          src="https://bootstrapious.com/i/snippets/sn-team/teacher-4.jpg"
                          alt=""
                          style={{ height: "50px", width: "50px" }}
                          className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                        />
                      )}
                      <div className="flex-grow-1 flex-shrink-1">
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-1">
                              <span className="fw-bold small">{item.resultUser[0].name}</span>

                              <span className="small">
                                <br />
                                {/* {formatDate(new Date(item.createdAt))} */}
                                {/* {item.createdAt} */}
                              </span>
                            </p>

                          </div>

                        </div>


                      </div>
                    </div>
                    <p className="small mb-0">
                      {item.comment}
                    </p>
                    <hr className="my-0" />
                  </div>
                </React.Fragment>
              )
            })
            }
            {/* <hr className="my-0" />
            <p className="my-2">
              <b>UserName : </b>
              Lorem ipsum fuztfehf skxvhnvyfp hwion emdcfjailo gildao bzozwh ko
              jjbvyeio.
            </p>
            <hr className="my-0" />
            <p className="my-2">
              <b>UserName : </b>
              Lorem ipsum fuztfehf skxvhnvyfp hwion emdcfjailo gildao bzozwh ko
              jjbvyeio.
            </p>
            <hr className="my-0" />
            <p className="my-2">
              <b>UserName : </b>
              Lorem ipsum fuztfehf skxvhnvyfp hwion emdcfjailo gildao bzozwh ko
              jjbvyeio.
            </p>
            <hr className="my-0" />
            <p className="my-2">
              <b>UserName : </b>
              Lorem ipsum fuztfehf skxvhnvyfp hwion emdcfjailo gildao bzozwh ko
              jjbvyeio.
            </p> */}
          </li>
        </ul>
      </div>
    );
  };

  const ShareButtons = (shareUrl, title) => {
    return (
      <div className="share-dropdown">
        <button
          className="dropdown-toggle border-0 "
          data-bs-toggle="dropdown"
          aria-expanded="false"
          aria-haspopup="true"
        >
          <FontAwesomeIcon icon={faShareAlt} title="Share this Quotes" className="text-primary" />
        </button>
        <ul className="dropdown-menu p-2">
          <li>
            <WhatsappShareButton url={shareUrl} title={title} className="ms-1">
              <FontAwesomeIcon icon={faWhatsapp} title="Share on Whatsapp" />
            </WhatsappShareButton>

            <FacebookShareButton url={shareUrl} quote={title} className="ms-1">
              <FontAwesomeIcon
                icon={faFacebookSquare}
                title="Share on Facebook"
                className="ms-1"
              />
            </FacebookShareButton>

            <TwitterShareButton url={shareUrl} title={title} className="ms-1">
              <FontAwesomeIcon
                icon={faTwitter}
                title="Share on Twitter"
                className="ms-1"
              />
            </TwitterShareButton>

            <TelegramShareButton url={shareUrl} title={title} className="ms-1">
              <FontAwesomeIcon
                icon={faTelegram}
                title="Share on Telegram"
                className="ms-1"
              />
            </TelegramShareButton>

            {/* LinkedIn Share Button */}
            <LinkedinShareButton url={shareUrl} title={title} className="ms-1">
              <FontAwesomeIcon
                icon={faLinkedin}
                title="Share on Linkedin"
                className="ms-1"
              />
            </LinkedinShareButton>

            {/* Pinterest Share Button */}
            <PinterestShareButton url={shareUrl} title={title} className="ms-1">
              <FontAwesomeIcon
                icon={faPinterest}
                title="Share on Pinterest"
                className="ms-1"
              />
            </PinterestShareButton>

            {/* Email Share Button */}
            <EmailShareButton
              url={shareUrl}
              subject={title}
              body="Check out this link"
              className="ms-1"
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                title="Share on Email"
                className="ms-1"
              />
            </EmailShareButton>

            {/* Tumblr Share Button */}
            <TumblrShareButton url={shareUrl} title={title} className="ms-1">
              <FontAwesomeIcon
                icon={faTumblr}
                title="Share on Tumblr"
                className="ms-1"
              />
            </TumblrShareButton>

            {/* Line Share Button */}
            <LineShareButton url={shareUrl} title={title} className="ms-1">
              <FontAwesomeIcon
                icon={faLine}
                title="Share on Line"
                className="ms-1"
              />
            </LineShareButton>

            {/* Pocket Share Button */}
            <PocketShareButton url={shareUrl} title={title} className="ms-1">
              <FontAwesomeIcon
                icon={faGetPocket}
                title="Share on Pocket"
                className="ms-1"
              />
            </PocketShareButton>
            {/* Add other sharing options here */}
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div>
      <ToastContainer
        style={{ top: "20px", right: "20px", position: "fixed" }}
        className="p-3"
      >
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
        //  message={toastMessage.content}
        // message={createMarkup(toastMessage)}
        >
          <Toast.Header>
            {toastMessage.cat}
          </Toast.Header>
          <Toast.Body><p >
            {toastMessage.content == 'login' ?
              <span>
                user need to login. Click <Link to="/login">login</Link> to proceed.
              </span>
              :
              toastMessage.content

            }
          </p></Toast.Body>
        </Toast>
      </ToastContainer>
      {/* <div>

        Add
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-2112315427789727"
          data-ad-slot="YOUR_AD_SLOT"
          data-ad-format="auto"
        ></ins>
      </div> */}
      <div className="row g-3">
        {/* <div className="row row-cols-1 row-cols-md-3 g-3"> */}
        {quoteData.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {/* {index % 10 === 0 ?
                <div className={
                  index % 4 == 0 ? "col-lg-6" : "col-lg-3"
                }>
                  <div className="card h-100">
                    <div className="card-body">
                      
                      Adds
                    </div>
                  </div>
                </div>
                : undefined} */}

              <div
                className={
                  item.resultCat[0].name == "Story" ? "col-lg-6" : "col-lg-3"
                }
              >
                <div className="card h-100 ">
                  <div className="card-header ">
                    <Link to={"/user/" + item.userId}>
                      {item.resultUser[0].photo ? (
                        <img
                          src={baseURL + "uploads/" + item.resultUser[0].photo}
                          style={{
                            height: "30px",
                            width: "30px",
                            marginRight: "5px",
                          }}
                          className="img-fluid rounded-circle"
                          alt={item.photo}
                        />
                      ) : (
                        <img
                          src="https://bootstrapious.com/i/snippets/sn-team/teacher-4.jpg"
                          alt=""
                          style={{ height: "50px", width: "50px" }}
                          className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                        />
                      )}

                      <span className=" mb-0">{item.resultUser[0].name}</span>
                    </Link>{" "}
                    ( Follower {item.totalFollower} )
                  </div>

                  {/* <img src="https://mdbcdn.b-cdn.net/img/new/standard/city/042.webp" className="card-img-top"
                    alt="Palm Springs Road" /> */}

                  <div
                    className="card-body bg-opacity-25"
                    style={{ backgroundColor: "#ebebeb" }}
                  >
                    <h3 className="text-center cursive-text">
                      <Link to={"/category/" + item.category}>
                        {item.resultCat[0].name}
                      </Link>
                    </h3>
                    <p
                      className="h4 card-text cursive-text"
                      style={{ textAlign: "justify" }}
                    >
                      {item.quote}
                    </p>
                    <h6 className="card-title">
                      {"- "}
                      {
                        item.resultAuthor[0]?.name === "By Self" ?

                          <Link to={"/user/" + item.userId}>
                            {item.resultUser[0]?.name}
                          </Link>

                          :

                          <Link to={"/author/" + item.author}>
                            {item.resultAuthor[0]?.name}
                          </Link>

                      }

                      {/* ( {item.resultCat[0].name} ) */}
                    </h6>
                    {/* <small className='text-muted'>
                    {formatDate(new Date(item.createdAt))}
                  </small> */}
                  </div>
                  <div className="card-footer d-flex justify-content-between">
                    <div className="d-flex">
                      {" "}
                      {CommentButtons(
                        item._id,
                        item.quote,
                        index,
                        item.totalComment
                      )}
                      <a
                        className="mx-1"
                        onClick={() => quoteLike(item._id, item.quote)}
                      >
                        {
                          //item.totalLike !=0 ?
                          userSessionExist() ? (
                            item.userStatus == "Like" ? (
                              <FontAwesomeIcon
                                icon={faHeart}
                                title="you already liked this quote"
                              // style={{ color: "red" }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={farHeart}
                                title="like this quote"
                              // style={{ color: "red" }}
                              />
                            )
                          ) : (
                            <FontAwesomeIcon
                              icon={farHeart}
                              title="like this quote"
                            // style={{ color: "red" }}
                            />
                          )
                        }{" "}
                      </a>
                      {item.totalLike}
                      {/* <div
                        className="mx-2"
                        title="Report about this quote"
                        onClick={() => quoteReport(item._id, item.quote)}
                      >
                        <FontAwesomeIcon icon={faExclamationTriangle} className="text-primary" />
                      </div> */}
                      <div className="report-section" >
                        <button className="dropdown-toggle border-0"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          title="Report About this Quote">
                          <FontAwesomeIcon icon={faExclamationTriangle} className="text-primary" />
                        </button>
                        <ul className="dropdown-menu p-1"
                          style={{
                            
                            // width: "calc(100% - 30px)",
                            // transform: "translate(10px, 279px)"
                          }}>
                          <li className="">
                            <input
                              name='txtReport'
                              placeholder="Explain the Issue"
                              className="no-focus-outline border-0"
                              value={userCommentReport[index] || ''}
                              onChange={(e) => handleInputReportChange(e, index)}
                              style={{ width: "65%" }}
                            />
                            <button className="border-0" style={{ width: "35%" }}
                              onClick={() => sendReport(item._id, item.quote, userCommentReport[index])}
                            >
                              Submit Report
                            </button>
                            <small>Please provide details on why you are reporting this quote, such as any offensive or inappropriate content you've noticed. Your feedback helps maintain a safe and respectful community.</small>
                          </li>
                        </ul>

                      </div>
                      <div
                        className="mx-2"
                        title="Copy this Quote"
                        onClick={() =>
                          quoteCopy(
                            '"' +
                            item.quote +
                            '" ( ' +
                            item.resultAuthor[0].name +
                            " )"
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faCopy} className="text-primary" />
                      </div>
                      <div
                        className="mx-2"
                        title="Create Wallpaper"
                        onClick={() =>
                          quotePopupOpen({
                            id: item._id,
                            category: item.resultCat[0].name,
                            quote: item.quote,
                            user: item.resultUser[0].name,
                            userImage:
                              baseURL + "uploads/" + item.resultUser[0].photo,
                            author: item.resultAuthor[0].name,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faImages}
                          className="text-primary"
                        />
                      </div>
                    </div>
                    <div>

                      {ShareButtons(
                        "http://quotes.ansarimohammad.com/",
                        item.quote
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <AddQuoteWallpaper
        show={showPopup}
        onHide={closePopup}
        value={popupValue}
      />
    </div>
  );
};
